import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, message } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const RoleTask = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [roleTask, setRoleTask] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentRoleTask, setCurrentRoleTask] = useState(null);

  const [roleGroup, setRoleGroup] = useState([]);
  const [selectedRoleGroup, setSelectedRoleGroup] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredRoleTask, setFilteredRoleTask] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalRoleTask, setOriginalRoleTask] = useState([]);

  const { Option } = Select;

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewRoleTask = storedPrivileges.includes("VIEW_ROLE_TASK");
  const canAddRoleTask = storedPrivileges.includes("ADD_ROLE_TASK");
  const canUpdateRoleTask = storedPrivileges.includes("UPDATE_ROLE_TASK");
  const canRemoveRoleTask = storedPrivileges.includes("DELETE_ROLE_TASK");

  const [filterOptions, setFilterOptions] = useState({
    roleGroup: selectedRoleGroup,
    // selectedCategory: "",
    // selectedSubcategory: "",
    // selectedLevel: "",
    // selectedLevelDetail: "",
    // selectedReporter: "",
    // selectedReported: "",
    // selectedStatus: "",
  });

  const showModal = (roleTask) => {
    setCurrentRoleTask(roleTask); // Set the selected roleTask
    form.setFieldsValue({
      roleGroup: roleTask.roleGroup,
      roleTask: roleTask.roleTask,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentRoleTask(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Hapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah anda yakin untuk menghapus data?",
          okText: "Delete",
          okType: "danger",
          cancelText: "Cancel",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/role-task/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  roleTaskID: record.roleTaskID, // Use the record's roleTaskID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Role Task deleted successfully");
              message.success("Role Task deleted successfully");

              // Refresh the category list after successful deletion
              const data = await getRoleTask();
              setRoleTask(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Error deleting role task:", error);
              message.error("Error deleting role task");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Deletion was cancelled")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Error during confirmation:", error);
    }
  };

  const getRoleTask = async () => {
    try {
      const response = await fetch(BASE_URL + "/role-task/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/role-task/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          roleTaskID: currentRoleTask.roleTaskID,
          roleGroup: values.roleGroup,
          roleTask: values.roleTask,
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      console.log("Role Task updated successfully");
      message.success("Role Task deleted successfully");
      setUpdateVisible(false);
      // Refresh the role task list after successful update
      getRoleTask().then((data) => {
        setRoleTask(data);
        setOriginalRoleTask(data); // Update originalRoleTask with the new data
        setFilteredRoleTask(data); // Update filteredRoleTask with the new data
      });
    } catch (error) {
      console.error("Error updating role task:", error);
    }
  };

  const handleAdd = () => {
    setSelectedRoleGroup(""); // Clear the selected role group
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/role-task/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          roleGroup: selectedRoleGroup,
          roleTask: values.roleTask,
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      console.log("Role Task added successfully");
      setAddVisible(false);
      // Refresh the role task list after successful addition
      getRoleTask().then((data) => {
        setRoleTask(data);
        setOriginalRoleTask(data); // Update originalRoleTask with the new data
        setFilteredRoleTask(data); // Update filteredRoleTask with the new data
      });
    } catch (error) {
      console.error("Error adding role task:", error);
    }
  };

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      roleGroup: selectedRoleGroup,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/role-task/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setRoleTask(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  useEffect(() => {
    getRoleTask().then((data) => {
      const initialRoleTask = data || [];
      setRoleTask(initialRoleTask);
      setOriginalRoleTask(initialRoleTask);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalRoleTask !== null) {
      const filteredData = originalRoleTask.filter((roleTask) =>
        roleTask.roleGroup.toLowerCase().includes(searchText.toLowerCase()) ||
        roleTask.roleTask.toLowerCase().includes(searchText.toLowerCase())
      );

      setRoleTask(filteredData);
    }
  }, [searchText, originalRoleTask]);

  const columns = [
    {
      title: "#",
      dataIndex: "roleTaskID", // You can adjust this based on your API response structure
      key: "roleTaskID",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Role Group",
      dataIndex: "roleGroup", // Adjust this based on your API response structure
      key: "roleGroup",
    },
    {
      title: "Role Task",
      dataIndex: "roleTask", // Adjust this based on your API response structure
      key: "roleTask",
    },
  ];

  if (canUpdateRoleTask || canRemoveRoleTask) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateRoleTask && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Update</Button>
          )}

          {canRemoveRoleTask && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Delete</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <div className="row g-3">
        <div className="filter-container" style={{ display: 'flex', width: '100%' }}>
          {/* Kolom Filter 1 */}
          <div className="filter-column" style={{ flex: 1, marginRight: '8px' }}>
            <Form.Item
              label="Role Group"
              name="roleGroup" // Change to the appropriate field name for the selected category
            >
              <Select
                placeholder="ALL"
                onChange={(value) => setSelectedRoleGroup(value)} // Update the state with selected role group
              >
                <Select.Option value=""> All </Select.Option>
                <Select.Option value="KATEGORI_LAPORAN">Kategori Laporan</Select.Option>
                <Select.Option value="SUBKATEGORI_LAPORAN">Sub Kategori Laporan</Select.Option>
                <Select.Option value="PELAPOR">Pelapor</Select.Option>
                <Select.Option value="TERLAPOR">Terlapor</Select.Option>
                <Select.Option value="RUANG LINGKUP TERLAPOR">Ruang Lingkup Terlapor</Select.Option>
                <Select.Option value="SUB RUANG LINGKUP TERLAPOR">Sub Ruang Lingkup Terlapor</Select.Option>
                <Select.Option value="WAKTU">Waktu</Select.Option>
                <Select.Option value="USERS">Users</Select.Option>
                <Select.Option value="ROLE">Role</Select.Option>
                <Select.Option value="ROLE TASK">Role Task</Select.Option>
                <Select.Option value="LAPORAN">Laporan</Select.Option>
                <Select.Option value="DATA DIRI PELAPOR">Data Diri Pelapor</Select.Option>
                <Select.Option value="TINDAKAN">Tindakan</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className="filter-column" style={{ flex: '0 0 auto' }}>
            <Form.Item>
              <Button type="primary" onClick={handleFilter}>
                Filter
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>

      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        {canAddRoleTask && (
          <Col><Button type="primary" onClick={handleAdd}>Add Role Task</Button> </Col>
        )}
        <Col>
          <div className="search-bar">
            <Input
              placeholder="Mencari..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
      </Row>

      {roleTask && roleTask.length > 0 && canViewRoleTask ? (
        <Table
          dataSource={roleTask}
          columns={columns}
          pagination={{
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
            total: roleTask.length,
            showSizeChanger: false,
            showQuickJumper: false,
            showLessItems: true,
          }}
        />
      ) : (
        <Table
          dataSource={[]}
          columns={columns}
          pagination={false} // Disable pagination when there is no data
        />
      )}

      {/* Modal for Adding Categories */}
      {canAddRoleTask && (
        <Modal
          title="Tambah Role Task"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Role Group"
              name="roleGroup" // Change to the appropriate field name for the selected role group
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih role group!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih role group"
                onChange={(value) => setSelectedRoleGroup(value)} // Update the state with selected role group
              >
                <Option value="DASHBOARD">Dashboard</Option>
                <Option value="KATEGORI_LAPORAN">Kategori Laporan</Option>
                <Option value="SUBKATEGORI_LAPORAN">Sub Kategori Laporan</Option>
                <Option value="PELAPOR">Pelapor</Option>
                <Option value="TERLAPOR">Terlapor</Option>
                <Option value="RUANG LINGKUP TERLAPOR">Ruang Lingkup Terlapor</Option>
                <Option value="SUB RUANG LINGKUP TERLAPOR">Sub Ruang Lingkup Terlapor</Option>
                <Option value="WAKTU">Waktu</Option>
                <Option value="USERS">Users</Option>
                <Option value="ROLE">Role</Option>
                <Option value="ROLE TASK">Role Task</Option>
                <Option value="LAPORAN">Laporan</Option>
                <Option value="ALL_LAPORAN">All Laporan</Option>
                <Option value="DATA DIRI PELAPOR">Data Diri Pelapor</Option>
                <Option value="TINDAKAN">Tindakan</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Role Task"
              name="roleTask"
              rules={[
                {
                  required: true,
                  message: "Silahkan masukkan role task!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateRoleTask && (
        <Modal
          title="Perbarui Role Task"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Role Group"
              name="roleGroup" // Change to the appropriate field name for the selected role group
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih role group!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih role group"
                onChange={(value) => setSelectedRoleGroup(value)} // Update the state with selected role group
              >
                <Option value="DASHBOARD">Dashboard</Option>
                <Option value="KATEGORI_LAPORAN">Kategori Laporan</Option>
                <Option value="SUBKATEGORI_LAPORAN">Sub Kategori Laporan</Option>
                <Option value="PELAPOR">Pelapor</Option>
                <Option value="TERLAPOR">Terlapor</Option>
                <Option value="RUANG LINGKUP TERLAPOR">Ruang Lingkup Terlapor</Option>
                <Option value="SUB RUANG LINGKUP TERLAPOR">Sub Ruang Lingkup Terlapor</Option>
                <Option value="WAKTU">Waktu</Option>
                <Option value="USERS">Users</Option>
                <Option value="ROLE">Role</Option>
                <Option value="ROLE TASK">Role Task</Option>
                <Option value="LAPORAN">Laporan</Option>
                <Option value="ALL_LAPORAN">All Laporan</Option>
                <Option value="DATA DIRI PELAPOR">Data Diri Pelapor</Option>
                <Option value="TINDAKAN">Tindakan</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Role Task"
              name="roleTask"
              rules={[
                {
                  required: true,
                  message: "Silahkan masukkan role task!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default RoleTask;