import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { IoFilter } from "react-icons/io5";
import { getApiConfig } from "../lib/getApiConfig";

const ScopeDetail = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [scopeDetail, setScopeDetail] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentScopeDetail, setCurrentScopeDetail] = useState(null);

  const [scope, setScope] = useState([]);
  const [selectedScope, setSelectedScope] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredScopeDetail, setFilteredScopeDetail] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalScopeDetail, setOriginalScopeDetail] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewLevelDetail = storedPrivileges.includes("VIEW_LEVEL_DETAIL");
  const canAddLevelDetail = storedPrivileges.includes("ADD_LEVEL_DETAIL");
  const canUpdateLevelDetail = storedPrivileges.includes("UPDATE_LEVEL_DETAIL");
  const canRemoveLevelDetail = storedPrivileges.includes("DELETE_LEVEL_DETAIL");

  const [filterOptions, setFilterOptions] = useState({
    tingkatanID: selectedScope,
    // selectedCategory: "",
    // selectedSubcategory: "",
    // selectedLevel: "",
    // selectedLevelDetail: "",
    // selectedReporter: "",
    // selectedReported: "",
    // selectedStatus: "",
  });

  let filteredReport = [];
  if (scopeDetail && Array.isArray(scopeDetail)) {
    filteredReport = scopeDetail.filter((item) => {
      // Implement your filtering logic here using filterOptions
      const {
        scope,
      } = item;

      return (
        (!selectedScope || selectedScope === scope)
      );
    });
  }

  const showModal = (scopeDetail) => {
    setCurrentScopeDetail(scopeDetail); // Set the selected scopeDetail
    form.setFieldsValue({
      tingkatan: scopeDetail.tingkatan,
      tingkatanDetail: scopeDetail.tingkatanDetail,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentScopeDetail(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/scope-detail/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  tingkatanDetailID: record.tingkatanDetailID, // Use the record's tingkatanDetailID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Detail Ruang Lingkup");
              message.success("Berhasil Menghapus Detail Ruang Lingkup");

              // Refresh the category list after successful deletion
              const data = await getScopeDetail();
              setScopeDetail(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Detail Ruang Lingkup:", error);
              message.error("Gagal Menghapus Detail Ruang Lingkup");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const getScope = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetailID: currentScopeDetail.tingkatanDetailID,
          tingkatanID: selectedScope,
          tingkatanDetail: values.tingkatanDetail,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "TingkatanDetail Already Exists") {
        message.error("Detail Ruang Lingkup Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Detail Ruang Lingkup");
        message.success("Berhasil Memperbarui Detail Ruang Lingkup");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getScopeDetail().then((data) => {
          setScopeDetail(data);
          setOriginalScopeDetail(data); // Update originalScopeDetail with the new data
          setFilteredScopeDetail(data); // Update filteredScopeDetail with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Detail Ruang Lingkup:", error);
      message.error("Gagal Memperbarui Detail Ruang Lingkup");
    }
  };

  const handleAdd = () => {
    setSelectedScope(""); // Clear the selected scope
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanID: selectedScope,
          tingkatanDetail: values.tingkatanDetail,
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Tingkatan Detail Already Exists") {
        message.error("Detail Ruang Lingkup Sudah Ada");
      } else {
        console.log("Berhasil Menambah Detail Ruang Lingkup");
        message.success("Berhasil Menambah Detail Ruang Lingkup");
        setAddVisible(false);
        // Refresh the subcategory list after successful update
        getScopeDetail().then((data) => {
          setScopeDetail(data);
          setOriginalScopeDetail(data); // Update originalScopeDetail with the new data
          setFilteredScopeDetail(data); // Update filteredScopeDetail with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambah Detail Ruang Lingkup :", error);
      message.error("Gagal Menambah Detail Ruang Lingkup");
    }
  };

  const getScopeDetail = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      tingkatanID: selectedScope,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setScopeDetail(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  useEffect(() => {
    getScopeDetail().then((data) => {
      const initialScopeDetail = data || [];
      setScopeDetail(initialScopeDetail);
      setOriginalScopeDetail(initialScopeDetail);
    });

    getScope().then((data) => {
      setScope(data);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalScopeDetail !== null) {
      const filteredData = originalScopeDetail.filter((scopeDetail) =>
        scopeDetail.tingkatanDetail?.toLowerCase().includes(searchText.toLowerCase())
      );

      setScopeDetail(filteredData);
    }
  }, [searchText, originalScopeDetail]);

  const columns = [
    {
      title: "#",
      dataIndex: "tingkatanDetailID", // You can adjust this based on your API response structure
      key: "tingkatanDetailID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Ruang Lingkup Terlapor",
      dataIndex: "tingkatan", // Adjust this based on your API response structure
      key: "tingkatan",
    },
    {
      title: "Detail Ruang Lingkup Terlapor",
      dataIndex: "tingkatanDetail", // Adjust this based on your API response structure
      key: "tingkatanDetail",
    },
  ];

  if (canUpdateLevelDetail || canRemoveLevelDetail) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateLevelDetail && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveLevelDetail && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <Card style={{ marginBottom: '24px' }}>
        <p style={{ opacity: .6, fontWeight: "bold" }}>Filter Data</p>
        <div className="row g-3">
          <div className="d-grid four">
            <div style={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Ruang Lingkup Terlapor</label>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", width: "100%", flexGrow: '1' }}>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedScope(value)}
                  style={{ width: "100%", flexGrow: '1' }}
                >
                  <Select.Option value={0}> All </Select.Option>
                  {scope && scope.length > 0 ? (
                    scope.map((scope) => (
                      <Select.Option key={scope.tingkatanID} value={scope.tingkatanID}>
                        {scope.tingkatan}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silahkan Mengisi Tabel Ruang Lingkup Terlapor!</Select.Option>
                  )}
                </Select>
                <Button type="primary" onClick={handleFilter}>
                  <IoFilter />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddLevelDetail && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Detail Ruang Lingkup Terlapor</Button> </Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {scopeDetail && scopeDetail.length > 0 && canViewLevelDetail ? (
          <Table
            dataSource={scopeDetail}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: scopeDetail.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>

      {/* Modal for Adding Categories */}
      {canAddLevelDetail && (
        <Modal
          title="Tambah Detail Ruang Lingkup Terlapor"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Ruang Lingkup Terlapor"
              name="tingkatanID" // Change to the appropriate field name for the selected category
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih ruang lingkup terlapor!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih ruang lingkup terlapor"
                onChange={(value) => setSelectedScope(value)}
              >
                {scope && scope.length > 0 ? (
                  scope.map((scope) => (
                    <Select.Option key={scope.tingkatanID} value={scope.tingkatanID}>
                      {scope.tingkatan}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="0">Silahkan mengisi tabel ruang lingkup terlapor!</Select.Option>
                )}

                {/* {scope.map((scope) => (
                <Select.Option key={scope.tingkatanID} value={scope.tingkatanID}>
                  {scope.tingkatan}
                </Select.Option>
              ))} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Detail Ruang Lingkup Terlapor"
              name="tingkatanDetail"
              rules={[
                {
                  required: true,
                  message: "Silahkan mengisi detail ruang lingkup terlapor!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateLevelDetail && (
        <Modal
          title="Perbarui Detail Ruang Lingkup Terlapor"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Ruang Lingkup Terlapor"
              name="tingkatanID" // Change to the appropriate field name for the selected category
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih ruang lingkup terlapor!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih ruang lingkup terlapor"
                onChange={(value) => setSelectedScope(value)}
              >
                {scope && scope.length > 0 ? (
                  scope.map((scope) => (
                    <Select.Option key={scope.tingkatanID} value={scope.tingkatanID}>
                      {scope.tingkatan}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="0">Silahkan mengisi tabel ruang lingkup!</Select.Option>
                )}

                {/* {scope.map((scope) => (
                <Select.Option key={scope.tingkatanID} value={scope.tingkatanID}>
                  {scope.tingkatan}
                </Select.Option>
              ))} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Detail Ruang Lingkup Terlapor"
              name="tingkatanDetail"
              rules={[
                {
                  required: true,
                  message: "Silahkan mengisi detail ruang lingkup terlapor!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default ScopeDetail;
