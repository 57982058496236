import React, { useState, useEffect } from "react";
import { Table, Input, Row, Col } from "antd";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";
import { SearchOutlined } from "@ant-design/icons";

const DataDiri = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [dataDiri, setDataDiri] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [filteredDataDiri, setFilteredDataDiri] = useState([]);

  const [originalDataDiri, setOriginalDataDiri] = useState([]);

  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewPersonalData = storedPrivileges.includes("VIEW_PERSONAL_DATA");

  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('levelDetail')) || [];

  const getDataDiri = async () => {
    try {
      const response = await fetch(BASE_URL + "/target/list-data-pelapor", {
        method: "POST",
        headers: getApiConfig(),
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getDataDiri().then((data) => {
      setDataDiri(data);
      setOriginalDataDiri(data);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalDataDiri !== null) {
      const filteredData = originalDataDiri.filter((dataDiri) =>
        dataDiri.kodeLaporan.toLowerCase().includes(searchText.toLowerCase()) ||
        dataDiri.nama.toLowerCase().includes(searchText.toLowerCase()) ||
        dataDiri.noHP.toLowerCase().includes(searchText.toLowerCase())
      );

      setDataDiri(filteredData);
    }
  }, [searchText, originalDataDiri]);

  const columns = [
    {
      title: "#",
      dataIndex: "dataDiriID", // You can adjust this based on your API response structure
      key: "dataDiriID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Kode Laporan",
      dataIndex: "kodeLaporan", // Adjust this based on your API response structure
      key: "kodeLaporan",
    },
    {
      title: "Nama Pelapor",
      dataIndex: "nama", // Adjust this based on your API response structure
      key: "nama",
    },
    {
      title: "Nomor Handphone Pelapor",
      dataIndex: "noHP", // Adjust this based on your API response structure
      key: "noHP",
    },
  ];

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col></Col>
        <Col>
          <div className="search-bar">
            <Input
              placeholder="Mencari..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </Col>
      </Row>

      {dataDiri && dataDiri.length > 0 && canViewPersonalData ? (
        <Table
          dataSource={dataDiri}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
            total: dataDiri.length,
            showSizeChanger: false,
            showQuickJumper: false,
            showLessItems: true,
          }}
        />
      ) : (
        <Table
          dataSource={[]}
          columns={columns}
          pagination={false} // Disable pagination when there is no data
        />
      )}
    </div>
  );
};

export default DataDiri;
