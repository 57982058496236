import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const Scope = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [scope, setScope] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentScope, setCurrentScope] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [filteredScope, setFilteredScope] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalScope, setOriginalScope] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewLevel = storedPrivileges.includes("VIEW_LEVEL");
  const canAddLevel = storedPrivileges.includes("ADD_LEVEL");
  const canUpdateLevel = storedPrivileges.includes("UPDATE_LEVEL");
  const canRemoveLevel = storedPrivileges.includes("DELETE_LEVEL");

  const showModal = (scope) => {
    setCurrentScope(scope); // Set the selected scopes
    form.setFieldsValue({
      tingkatanID: scope.tingkatanID,
      tingkatan: scope.tingkatan,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentScope(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/scope/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  tingkatanID: record.tingkatanID, // Use the record's tingkatanID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil menghapus ruang lingkup");
              message.success("Berhasil menghapus ruang lingkup");

              // Refresh the scope list after successful deletion
              const data = await getScope();
              setScope(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal menghapus ruang lingkup :", error);
              message.error("Gagal menghapus ruang lingkup");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/scope/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanID: currentScope.tingkatanID,
          tingkatan: values.tingkatan,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Tingkatan Already Exists") {
        message.error("Ruang Lingkup Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Ruang Lingkup");
        message.success("Berhasil Memperbarui Ruang Lingkup");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getScope().then((data) => {
          setScope(data);
          setOriginalScope(data); // Update originalScope with the new data
          setFilteredScope(data); // Update filteredScope with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Ruang Lingkup :", error);
      message.error("Gagal Memperbarui Ruang Lingkup");
    }
  };

  const handleAdd = () => {
    setCurrentScope(null); // Clear current scope
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/scope/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatan: values.tingkatan,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Tingkatan Already Exists") {
        message.error("Ruang Lingkup Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Ruang Lingkup");
        message.success("Berhasil Menambahkan Ruang Lingkup");
        setAddVisible(false);
        // Refresh the subcategory list after successful update
        getScope().then((data) => {
          setScope(data);
          setOriginalScope(data); // Update originalScope with the new data
          setFilteredScope(data); // Update filteredScope with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Ruang Lingkup :", error);
      message.error("Gagal Menambahkan Ruang Lingkup");
    }
  };

  const getScope = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getScope().then((data) => {
      const initialScope = data || [];
      setScope(initialScope);
      setOriginalScope(initialScope);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalScope !== null) {
      const filteredData = originalScope.filter((scope) =>
        scope.tingkatan.toLowerCase().includes(searchText.toLowerCase())
      );

      setScope(filteredData);
    }
  }, [searchText, originalScope]);

  const columns = [
    {
      title: "#",
      dataIndex: "tingkatanID", // You can adjust this based on your API response structure
      key: "tingkatanID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Ruang Lingkup Terlapor",
      dataIndex: "tingkatan", // Adjust this based on your API response structure
      key: "tingkatan",
    },
  ];

  if (canUpdateLevel || canRemoveLevel) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateLevel && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveLevel && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddLevel && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Ruang Lingkup Terlapor</Button> </Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {scope && scope.length > 0 && canViewLevel ? (
          <Table
            dataSource={scope}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: scope.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>

      {/* Modal for Adding Scope */}
      {canAddLevel && (
        <Modal
          title="Tambah Ruang Lingkup Terlapor"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Ruang Lingkup Terlapor"
              name="tingkatan"
              rules={[
                {
                  required: true,
                  message: "Silahkan masukkan ruang lingkup terlapor!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateLevel && (
        <Modal
          title="Perbarui Ruang Lingkup Terlapor"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Ruang Lingkup Terlapor"
              name="tingkatan"
              rules={[
                {
                  required: true,
                  message: "Silahkan masukkan ruang lingkup terlapor!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Scope;
