import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, Checkbox, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { IoFilter } from "react-icons/io5";
import { getApiConfig } from "../lib/getApiConfig";

const Users = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [users, setUsers] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [role, setRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredUsers, setFilteredUsers] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalUsers, setOriginalUsers] = useState([]);

  const [levelDetailList3, setLevelDetailList3] = useState([]);

  const [dataPrivilege, setDataPrivilege] = useState([]); //array of data for checkbox

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  const [levelDetailModalVisible, setLevelDetailModalVisible] = useState(false);

  const [currentUsers, setCurrentUsers] = useState(null);

  const [levelDetailList2, setLevelDetailList2] = useState([]);

  const [selectedLevelDetail, setSelectedLevelDetail] = useState([]);

  const [levelDetailCRUD2, setLevelDetailCRUD2] = useState([]);

  const [isLevelDetailAssignedMap2, setIsLevelDetailAssignedMap2] = useState({});

  const [levelDetailCRUD, setLevelDetailCRUD] = useState([]);

  // Use the user object and its privileges to conditionally render components
  const canViewUsers = storedPrivileges.includes("VIEW_USERS");
  const canAddUsers = storedPrivileges.includes("ADD_USERS");
  const canUpdateUsers = storedPrivileges.includes("UPDATE_USERS");
  const canRemoveUsers = storedPrivileges.includes("DELETE_USERS");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    role: selectedRole,
    // selectedCategory: "",
    // selectedSubcategory: "",
    // selectedLevel: "",
    // selectedLevelDetail: "",
    // selectedReporter: "",
    // selectedReported: "",
    // selectedStatus: "",
  });

  const showModal = (users) => {
    setCurrentUsers(users); // Set the selected users
    form.setFieldsValue({
      username: users.username,
      email: users.email,
      role: users.role,
      password: users.password,
    });
    setSelectedRole(users.role)
    setUpdateVisible(true); // Show the update modal
  };

  const closeLevelDetailModal = () => {
    setLevelDetailModalVisible(false);
    setLevelDetailCRUD2([]);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentUsers(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/users/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  usersID: record.usersID, // Use the record's usersID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Pengguna");
              message.success("Berhasil Menghapus Pengguna");

              // Refresh the category list after successful deletion
              const data = await getUsers();
              setUsers(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Pengguna :", error);
              message.error("Gagal Menghapus Pengguna");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const getRole = async () => {
    try {
      const response = await fetch(BASE_URL + "/role/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/users/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          usersID: currentUsers.usersID,
          username: values.username.toLowerCase(),
          email: values.email.toLowerCase(),
          password: values.password,
          role: selectedRole,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Username Already Exists") {
        message.error("Pengguna Sudah Ada!");
      } else {
        console.log("Berhasil Memperbarui Pengguna");
        message.success("Berhasil Memperbarui Pengguna");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getUsers().then((data) => {
          setUsers(data);
          setOriginalUsers(data); // Update originalUsers with the new data
          setFilteredUsers(data); // Update filteredUsers with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Pengguna :", error);
    }
  };

  const handleAdd = () => {
    setSelectedRole(""); // Clear the selected role
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/users/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          username: values.username.toLowerCase(),
          email: values.email.toLowerCase(),
          password: values.password,
          role: selectedRole,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Username Already Exists") {
        message.error("Username Sudah Terdaftar!");
      } else if (responseData.responseMessage === "Email Already Exists") {
        message.error("Email Sudah Terdaftar!");
      } else {
        console.log("Berhasil Menambahkan Pengguna");
        message.success("Berhasil Menambahkan Pengguna");
        setAddVisible(false);
        // Refresh the subcategory list after successful update
        getUsers().then((data) => {
          setUsers(data);
          setOriginalUsers(data); // Update originalUsers with the new data
          setFilteredUsers(data); // Update filteredUsers with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Pengguna :", error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await fetch(BASE_URL + "/users/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const fetchLevelDetail3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const levelDetailData = await response.json();
      return levelDetailData.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data level_detail :", error);
    }
  };

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      role: selectedRole,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/users/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setUsers(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  useEffect(() => {
    getUsers().then((data) => {
      const initialUsers = data || [];
      setUsers(initialUsers);
      setOriginalUsers(initialUsers);
    });

    getRole().then((data) => {
      setRole(data);
    });

    fetchLevelDetail3().then((data) => {
      setLevelDetailList3(data);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalUsers !== null) {
      const filteredData = originalUsers.filter((users) =>
        users.username.toLowerCase().includes(searchText.toLowerCase()) ||
        users.roleName.toLowerCase().includes(searchText.toLowerCase())
      );

      setUsers(filteredData);
    }
  }, [searchText, originalUsers]);

  const columns = [
    {
      title: "#",
      dataIndex: "usersID", // You can adjust this based on your API response structure
      key: "usersID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Username",
      dataIndex: "username", // Adjust this based on your API response structure
      key: "username",
    },
    {
      title: "Email",
      dataIndex: "email", // Adjust this based on your API response structure
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "roleName", // Adjust this based on your API response structure
      key: "roleName",
    },
  ];

  if (canRemoveUsers) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateUsers && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}
          {canRemoveUsers && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  const handleLevelDetailChange = (e, tingkatanDetailID) => {
    const isChecked = e.target.checked;

    setSelectedLevelDetail((prevSelectedTasks) => {
      if (isChecked) {
        // Add the task if checked
        return [...prevSelectedTasks, tingkatanDetailID];
      } else {
        // Remove the task if unchecked
        return prevSelectedTasks.filter((id) => id !== tingkatanDetailID);
      }
    });
  };

  const tingkatanGroups = [...new Set(levelDetailList3.map((task) => task.tingkatan))];

  return (
    <div>
      <Card style={{ marginBottom: '24px' }}>
        <p style={{ opacity: .6, fontWeight: "bold" }}>Filter Data</p>
        <div className="row g-3">
          <div className="d-grid four">
            <div style={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Role</label>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", width: "100%", flexGrow: '1' }}>
                <Select
                  placeholder="Pilih role"
                  onChange={(value) => setSelectedRole(value)}
                  style={{ width: "100%", flexGrow: '1' }}
                >
                  <Select.Option value={0}> All </Select.Option>
                  {role.map((category) => (
                    <Select.Option key={category.roleID} value={category.roleID}>
                      {category.roleName}
                    </Select.Option>
                  ))}
                </Select>
                <Button type="primary" onClick={handleFilter}>
                  <IoFilter />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddUsers && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Pengguna</Button></Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {users && users.length > 0 && canViewUsers ? (
          <Table
            dataSource={users}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: users.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>

      {/* Modal for Adding Categories */}
      {canAddUsers && (
        <Modal
          title="Tambah Pengguna"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Kolom ini wajib diisi!",
                },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9_]+$/),
                  message: "Username hanya boleh berupa huruf, angka, dan underscore (_)",
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Kolom ini wajib diisi!",
                },
                {
                  pattern: new RegExp(/^[A-Za-z0-9+_.-]+@(.+)$/),
                  message: "Harap isi email dengan format yang benar",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Kolom ini wajib diisi!",
                },
                {
                  max: 15,
                  message: "Password tidak boleh memiliki karakter lebih dari 15",
                },
                {
                  pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/),
                  message: "Password harus membuat huruf besar, huruf kecil, angka, dan karakter",
                },
              ]}
            >
              <Input.Password type="password" />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role" // Change to the appropriate field name for the selected category
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih role!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih role"
                onChange={(value) => setSelectedRole(value)}
              >
                {role.map((category) => (
                  <Select.Option key={category.roleID} value={category.roleID}>
                    {category.roleName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateUsers && (
        <Modal
          title="Perbarui Pengguna"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Kolom ini wajib diisi!",
                },
                {
                  pattern: new RegExp(/^[a-zA-Z0-9_]+$/),
                  message: "Username hanya boleh berupa huruf, angka, dan underscore (_)",
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Kolom ini wajib diisi!",
                },
                {
                  pattern: new RegExp(/^[A-Za-z0-9+_.-]+@(.+)$/),
                  message: "Harap isi email dengan format yang benar",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  max: 15,
                  message: "Password tidak boleh memiliki karakter lebih dari 15",
                },
                {
                  pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,}$/),
                  message: "Password harus membuat huruf besar, huruf kecil, angka, dan karakter",
                },
              ]}
            >
              <Input.Password placeholder="Kosongkan jika tidak ingin diupdate" type="password" />
            </Form.Item>
            <Form.Item
              label="Role"
              name="role" // Change to the appropriate field name for the selected category
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih role!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih role"
                onChange={(value) => setSelectedRole(value)}
              >
                {role.map((category) => (
                  <Select.Option key={category.roleID} value={category.roleID}>
                    {category.roleName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Modal
        title="Detail Ruang Lingkup"
        visible={levelDetailModalVisible}
        onCancel={closeLevelDetailModal}
        footer={null}
      >
        {tingkatanGroups.map((group) => (
          <div key={group}>
            <h3>{group}</h3>
            <Row gutter={16}>
              {levelDetailList3
                .filter((task) => task.tingkatan === group)
                .map((task) => (
                  <Col span={18} key={task.tingkatanDetailID}>
                    <Checkbox
                      disabled={true}
                      checked={levelDetailCRUD2.includes(task.tingkatanDetailID)}
                      onChange={(e) => handleLevelDetailChange(e, task.tingkatanDetailID)}
                    >
                      {task.tingkatanDetail}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default Users;