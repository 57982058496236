// export const BASE_URL = "http://localhost:8081";
// export const BASE_URL_NODE = "http://localhost:3001";
// export const SERVER_ENDPOINT = "http://localhost:8081"
// export const GOOGLE_OAUTH_CLIENT_ID = "394878713654-famv68oegql542qisrb4drq2o0pgbagl.apps.googleusercontent.com"
// export const GOOGLE_OAUTH_CLIENT_SECRET = "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"
// export const GOOGLE_OAUTH_REDIRECT = "http://localhost:8081/sessions/oauth/google"

// export const BASE_URL = "http://147.139.129.28:8080";
// export const BASE_URL_NODE = "http://localhost:3001";
// export const SERVER_ENDPOINT = "http://147.139.129.28:8080"
// export const GOOGLE_OAUTH_CLIENT_ID = "394878713654-famv68oegql542qisrb4drq2o0pgbagl.apps.googleusercontent.com"
// export const GOOGLE_OAUTH_CLIENT_SECRET = "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"
// export const GOOGLE_OAUTH_REDIRECT = "http://localhost:8081/sessions/oauth/google"

export const BASE_URL = "https://whistleblowing.id/backend/api";
export const BASE_URL_NODE = "http://localhost:3001";
export const SERVER_ENDPOINT = "https://whistleblowing.id/backend/api"
export const GOOGLE_OAUTH_CLIENT_ID = "394878713654-famv68oegql542qisrb4drq2o0pgbagl.apps.googleusercontent.com"
export const GOOGLE_OAUTH_CLIENT_SECRET = "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"
export const GOOGLE_OAUTH_REDIRECT = "http://localhost:8081/sessions/oauth/google"