import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import Cookies from 'js-cookie';

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];
  const history = useHistory();

  // Use the user object and its privileges to conditionally render components
  const canViewCategory = storedPrivileges.includes("VIEW_KATEGORI_LAPORAN");
  const canViewSubcategory = storedPrivileges.includes("VIEW_SUBKATEGORI_LAPORAN");
  const canViewPelapor = storedPrivileges.includes("VIEW_PELAPOR");
  const canViewTerlapor = storedPrivileges.includes("VIEW_TERLAPOR");
  const canViewLevel = storedPrivileges.includes("VIEW_LEVEL");
  const canViewLevelDetail = storedPrivileges.includes("VIEW_LEVEL_DETAIL");
  const canViewWaktu = storedPrivileges.includes("VIEW_WAKTU");
  const canViewUsers = storedPrivileges.includes("VIEW_USERS");
  const canViewRole = storedPrivileges.includes("VIEW_ROLE");
  const canViewRoleTask = storedPrivileges.includes("VIEW_ROLE_TASK");
  const canViewReport = storedPrivileges.includes("VIEW_REPORT");
  const canViewAllReport = storedPrivileges.includes("VIEW_ALL_REPORT");
  const canViewPersonalData = storedPrivileges.includes("VIEW_PERSONAL_DATA");
  const canViewAction = storedPrivileges.includes("VIEW_ACTION");
  const canViewDashboard = storedPrivileges.includes("VIEW_DASHBOARD");

  const handleLogout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("privileges");
    sessionStorage.removeItem("levelDetail");
    sessionStorage.removeItem("allReport");
    sessionStorage.removeItem("isGoogle");
    history.push("/sign-in");
  }

  const dashboard = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={color}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={color}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const category = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.93,19.2,17.33,4.6A2,2,0,0,0,15.92,4L6,4A2,2,0,0,0,4,6l0,9.92a2,2,0,0,0,.59,1.41l14.6,14.6a2,2,0,0,0,2.83,0l9.9-9.9A2,2,0,0,0,31.93,19.2ZM9.65,11.31a1.66,1.66,0,1,1,1.66-1.66A1.66,1.66,0,0,1,9.65,11.31Z"
        className="clr-i-solid clr-i-solid-path-1"
        fill={color} // Replace {color} with your desired color
      />
    </svg>,
  ];

  const subcategory = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 37.628 37.628"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.895,23.758L25.092,35.562c-0.488,0.487-1.128,0.731-1.77,0.731c-0.211,0-0.419-0.037-0.625-0.089
      c0.418-0.107,0.815-0.315,1.145-0.644l11.803-11.804c0.979-0.977,0.979-2.56,0-3.534L17.488,2.067
      c-0.333-0.333-0.752-0.546-1.199-0.651l0.243-0.043c0.807-0.142,1.629,0.116,2.206,0.694l18.156,18.156
      C37.872,21.199,37.872,22.782,36.895,23.758z M34.228,23.758L22.425,35.562c-0.488,0.487-1.128,0.731-1.77,0.731
      c-0.64,0-1.279-0.244-1.768-0.731L0.732,17.405c-0.578-0.578-0.837-1.401-0.694-2.206L1.822,5.181
      c0.184-1.031,0.992-1.839,2.023-2.023l10.019-1.784c0.807-0.142,1.629,0.116,2.206,0.694l18.156,18.156
      C35.206,21.199,35.206,22.782,34.228,23.758z M9.454,7.193c-0.985-1-2.595-1.012-3.595-0.027s-1.011,2.595-0.026,3.595
      c0.985,0.999,2.594,1.012,3.594,0.026C10.426,9.802,10.438,8.192,9.454,7.193z" />
    </svg>,
  ];

  const target = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M452,120h-76C376,53.726,322.274,0,256,0h0c-66.274,0-120,53.726-120,120H60c-33.137,0-60,26.863-60,60v272  c0,33.137,26.863,60,60,60h392c33.137,0,60-26.863,60-60V180C512,146.863,485.137,120,452,120z M256,60c33.137,0,60,26.863,60,60  H196C196,86.863,222.863,60,256,60z M402,260v20c0,16.569-13.431,30-30,30h0c-16.569,0-30-13.431-30-30v-20H170v20  c0,16.569-13.431,30-30,30h0c-16.569,0-30-13.431-30-30v-20c-16.569,0-30-13.431-30-30s13.431-30,30-30h292  c16.569,0,30,13.431,30,30S418.569,260,402,260z" />
    </svg>,
  ];

  const targetReported = [
    <svg
      width="25"
      height="25"
      viewBox="0 0 925 925"
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M352.9,53.4H441.6c33.601,0,61.601,24,68,55.7H560.2C553.4,49.7,502.8,3.4,441.6,3.4H352.9
			c-61.2,0-111.8,46.3-118.601,105.7H284.9C291.3,77.3,319.3,53.4,352.9,53.4z"/>
      <path d="M127.2,322.4c71.8,46.4,167.7,72,270.1,72c102.4,0,198.2-25.6,270.101-72C732,280.7,769.5,226.8,774.1,169.7
        C756.8,148,730.2,134.1,700.2,134.1H561h-50H283.6h-50H94.4c-29.9,0-56.6,13.9-73.9,35.6C25.1,226.8,62.6,280.6,127.2,322.4z"/>
      <path d="M363.1,461.1c0,15.601,12.7,28.3,28.301,28.3H403.1c15.601,0,28.301-12.699,28.301-28.3v-22.6H363.1V461.1L363.1,461.1z"
      />
      <path d="M94.4,617.201h419.5V553.5c0-7.699,3.1-15,8.6-20.4L618.2,439c5.399-5.3,12.6-8.2,20.1-8.2h156.2V243
        c-4.2,8.6-9,17.1-14.5,25.5c-21.5,32.9-52.2,62.3-91,87.4c-60.3,39-135.8,64.4-217.6,74.1v8.399V461
        c0,37.701-30.601,68.3-68.301,68.3H391.4c-37.7,0-68.301-30.6-68.301-68.3V438.4V430c-81.8-9.601-157.3-35.101-217.6-74.1
        c-38.8-25.1-69.5-54.5-91-87.4C9,260.1,4.2,251.6,0,243v279.8C0,574.9,42.3,617.201,94.4,617.201z"/>
      <path d="M659.6,453.6c-13.399,0-26.5,5.5-36,15l-69.1,69.602c-9.5,9.6-14.7,22.199-14.7,35.699v43.301v12.5v12.5V870.9
        c0,2.4,0.2,4.801,0.5,7.1c3.4,24.6,24.601,43.6,50.2,43.6h283.8c27.9,0,50.7-22.699,50.7-50.699V504.201
        c0-24.5-17.4-44.901-40.5-49.601c-3.3-0.7-6.7-1-10.1-1h-54.9H807h-12.5H659.6L659.6,453.6z M819.5,503.6h54.8
        c0.4,0,0.7,0.301,0.7,0.701V871c0,0.1,0,0.1,0,0.201c-0.1,0.299-0.3,0.5-0.6,0.5c0,0,0,0-0.101,0H590.5
        c-0.4,0-0.7-0.301-0.7-0.701V642.201v-12.5v-12.5v-33h36.7c22.9,0,41.5-18.602,41.5-41.5V503.6h126.5H807H819.5L819.5,503.6z"/>
      <path d="M750,617.301H641.1c-9.199,0-17.199,5-21.6,12.4c-2.2,3.699-3.4,7.898-3.4,12.5v0.1c0,13.799,11.2,25,25,25h182.7
        c13.8,0,25-11.201,25-25c0-13.801-11.2-25-25-25h-50.7H750z"/>
      <path d="M823.8,774.801H641.1c-13.8,0-25,11.199-25,25c0,13.799,11.2,25,25,25h182.7c13.8,0,25-11.201,25-25
        C848.8,786,837.6,774.801,823.8,774.801z"/>
      <path d="M823.8,696.1H641.1c-13.8,0-25,11.201-25,25c0,13.801,11.2,25,25,25h182.7c13.8,0,25-11.199,25-25
        C848.8,707.301,837.6,696.1,823.8,696.1z"/>
    </svg>,
  ];

  const users = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const roleTask = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 1920 1920"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1468.214 0v551.145L840.27 1179.089c-31.623 31.623-49.693 74.54-49.693 119.715v395.289h395.288c45.176 0 88.093-18.07 119.716-49.694l162.633-162.633v438.206H0V0h1468.214Zm129.428 581.3c22.137-22.136 57.825-22.136 79.962 0l225.879 225.879c22.023 22.023 22.023 57.712 0 79.848l-677.638 677.637c-10.616 10.503-24.96 16.49-39.98 16.49H903.516v-282.35c0-15.02 5.986-29.364 16.49-39.867Zm-920.005 548.095H338.82v112.94h338.818v-112.94Zm225.88-225.879H338.818v112.94h564.697v-112.94Zm734.106-202.5-89.561 89.56 146.03 146.031 89.562-89.56-146.031-146.031Zm-508.228-362.197H338.82v338.818h790.576V338.82Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const role = [
    <svg
      width="50"
      height="50"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44,63.3c0-3.4,1.1-7.2,2.9-10.2c2.1-3.7,4.5-5.2,6.4-8c3.1-4.6,3.7-11.2,1.7-16.2c-2-5.1-6.7-8.1-12.2-8
        s-10,3.5-11.7,8.6c-2,5.6-1.1,12.4,3.4,16.6c1.9,1.7,3.6,4.5,2.6,7.1c-0.9,2.5-3.9,3.6-6,4.6c-4.9,2.1-10.7,5.1-11.7,10.9
        c-1,4.7,2.2,9.6,7.4,9.6h21.2c1,0,1.6-1.2,1-2C45.8,72.7,44,68.1,44,63.3z M64,48.3c-8.2,0-15,6.7-15,15s6.7,15,15,15s15-6.7,15-15
        S72.3,48.3,64,48.3z M66.6,64.7c-0.4,0-0.9-0.1-1.2-0.2l-5.7,5.7c-0.4,0.4-0.9,0.5-1.2,0.5c-0.5,0-0.9-0.1-1.2-0.5
        c-0.6-0.6-0.6-1.7,0-2.5l5.7-5.7c-0.1-0.4-0.2-0.7-0.2-1.2c-0.2-2.6,1.9-5,4.5-5c0.4,0,0.9,0.1,1.2,0.2c0.2,0,0.2,0.2,0.1,0.4
        L66,58.9c-0.2,0.1-0.2,0.5,0,0.6l1.7,1.7c0.2,0.2,0.5,0.2,0.7,0l2.5-2.5c0.1-0.1,0.4-0.1,0.4,0.1c0.1,0.4,0.2,0.9,0.2,1.2
        C71.6,62.8,69.4,64.9,66.6,64.7z"
        fill={color}
      ></path>
    </svg>,
  ];

  const action = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8,29.8L29.6,27c-0.6-0.2-1.1-0.8-1.1-1.5V14.6c0-1.7-1.4-3-3.1-3h-0.3c-1.7,0-3.1,1.4-3.1,3v21.5
        c0,1.8-2.3,2.6-3.3,1l-2.1-4.4c-1.1-1.9-3.6-2.4-5.4-1.1l-1.3,1l6.9,16.3c0.3,0.7,1,1.1,1.8,1.1h18.1c0.9,0,1.6-0.6,1.8-1.4
        l3.2-11.4C42.5,34,40.7,30.9,37.8,29.8z"
        fill={color}
      ></path>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2,23v-8.2c0-0.2,0-0.2,0-0.4c0.2-4.1,3.5-7.4,7.6-7.6c0.2,0,0.2,0,0.4,0c0.2,0,0.2,0,0.4,0
        c4.1,0.2,7.4,3.5,7.6,7.6c0,0.2,0,0.2,0,0.4V23c0,0.7,0.9,1,1.4,0.6c2.2-2.3,3.5-5.4,3.5-8.7c0-7.4-6.4-13.4-14-12.7
        c-5.8,0.6-10.6,5-11.5,10.7c-0.6,4,0.6,7.9,3.3,10.7C16.3,24,17.2,23.7,17.2,23z"
        fill={color}
      ></path>
    </svg>,
  ];

  const report = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15,5L15,5c0-0.552-0.448-1-1-1h-1.051C12.982,4.162,13,4.329,13,4.5v2C13,7.878,11.879,9,10.5,9
        c-0.107,0-0.215-0.007-0.325-0.021C8.935,8.82,8,7.732,8,6.448V6.303c0-0.764,0.549-1.446,1.307-1.541
        C9.552,4.731,9.787,4.76,10,4.836V4.5C10,4.224,10.224,4,10.5,4S11,4.224,11,4.5v1.972c0,0.25-0.171,0.482-0.418,0.522
        C10.554,6.998,10.527,7,10.5,7C10.224,7,10,6.775,10,6.5V6.25c0-0.304-0.271-0.544-0.584-0.493C9.17,5.797,9,6.028,9,6.277v0.162
        c0,0.778,0.566,1.472,1.34,1.553C10.394,7.997,10.447,8,10.5,8C11.327,8,12,7.327,12,6.5v-2C12,3.672,11.328,3,10.5,3
        C9.848,3,9.298,3.419,9.092,4H8.051H6C5.448,4,5,4.448,5,5v10c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1L15,5L15,5z M11.5,13h-3
        C8.224,13,8,12.776,8,12.5C8,12.224,8.224,12,8.5,12h3c0.276,0,0.5,0.224,0.5,0.5C12,12.776,11.776,13,11.5,13z M11.5,11h-3
        C8.224,11,8,10.776,8,10.5C8,10.224,8.224,10,8.5,10h3c0.276,0,0.5,0.224,0.5,0.5C12,10.776,11.776,11,11.5,11z M30,25.618V26
        c0,1.657-1.343,3-3,3H5c-1.657,0-3-1.343-3-3v-0.382C2.734,26.458,3.8,27,5,27h22C28.2,27,29.266,26.458,30,25.618z M5,26h22
        c1.657,0,3-1.343,3-3V8c0-1.657-1.343-3-3-3H16v10c0,1.103-0.897,2-2,2H6c-1.103,0-2-0.897-2-2V5.184C2.837,5.597,2,6.696,2,8v15
        C2,24.657,3.343,26,5,26z M5.5,22h2.793l2.854-2.854C11.24,19.053,11.367,19,11.5,19h3.793l2.854-2.854
        c0.195-0.195,0.512-0.195,0.707,0L20.707,18h1.586l3-3H22.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4
        c0.065,0,0.13,0.013,0.191,0.038c0.122,0.051,0.22,0.148,0.271,0.271C26.987,14.37,27,14.435,27,14.5v4c0,0.276-0.224,0.5-0.5,0.5
        S26,18.776,26,18.5v-2.793l-3.146,3.146C22.76,18.947,22.633,19,22.5,19h-2c-0.133,0-0.26-0.053-0.354-0.146L18.5,17.207
        l-2.646,2.646C15.76,19.947,15.633,20,15.5,20h-3.793l-2.854,2.854C8.76,22.947,8.633,23,8.5,23h-3C5.224,23,5,22.776,5,22.5
        S5.224,22,5.5,22z"
        fill={color}
      ></path>
    </svg>,
  ];

  const scope = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.289,18.798c-0.745-0.431-1.66-0.434-2.405-0.008l-9.158,5.233c-0.75,0.429-1.214,1.227-1.214,2.092v9.436
        c0,1.331,1.079,2.41,2.41,2.41h9.158c1.331,0,2.41-1.079,2.41-2.41V20.883C30.489,20.024,30.031,19.229,29.289,18.798z"/>
      <path d="M12.899,28.164c-0.744-0.435-1.66-0.436-2.405-0.008l-9.279,5.303C0.266,34-0.2,35.113,0.081,36.169
        c0.28,1.057,1.236,1.792,2.329,1.792h9.28c1.331,0,2.41-1.079,2.41-2.41v-5.304C14.101,29.39,13.643,28.594,12.899,28.164z"/>
      <path d="M45.799,9.364c-0.744-0.432-1.66-0.434-2.405-0.007l-9.279,5.302c-0.751,0.429-1.215,1.228-1.215,2.093v18.799
        c0,1.331,1.079,2.41,2.41,2.41h9.279c1.331,0,2.41-1.079,2.41-2.41V11.449C47,10.59,46.542,9.795,45.799,9.364z"/>
    </svg>,
  ];

  const scopeDetail = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22,17v4a1,1,0,0,1-1,1H17a1,1,0,0,1-1-1V17a1,1,0,0,1,1-1h1V14.5a.5.5,0,0,0-.5-.5H6.5a.5.5,0,0,0-.5.5V16H7a1,1,0,0,1,1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,1,1-1H4V13a1,1,0,0,1,1-1h6V9H9A1,1,0,0,1,8,8V3A1,1,0,0,1,9,2h6a1,1,0,0,1,1,1V8a1,1,0,0,1-1,1H13v3h6a1,1,0,0,1,1,1v3h1A1,1,0,0,1,22,17Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const waktu = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M21.7,11.7l-5,5C16.5,16.9,16.3,17,16,17
        c-0.1,0-0.3,0-0.4-0.1C15.2,16.8,15,16.4,15,16V7c0-0.6,0.4-1,1-1s1,0.4,1,1v6.6l3.3-3.3c0.4-0.4,1-0.4,1.4,0S22.1,11.3,21.7,11.7z"
        fill={color}
      ></path>
    </svg>,
  ];

  const dataDiri = [
    <svg
      width="25"
      height="25"
      viewBox="0 0 174.997 174.996"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M124.779,133.735H50.22c-1.796,0-3.243,1.443-3.243,3.246s1.44,3.252,3.243,3.252h74.559c1.79,0,3.239-1.449,3.239-3.252
			S126.582,133.735,124.779,133.735z"/>
      <path d="M124.779,148.538H87.501c-1.797,0-3.249,1.449-3.249,3.252c0,1.79,1.446,3.246,3.249,3.246h37.277
        c1.803,0,3.245-1.443,3.245-3.246S126.582,148.538,124.779,148.538z"/>
      <path d="M56.504,105.415c0,10.199,61.979,10.199,61.979,0c0-8.622-9.932-20.271-23.346-23.675
        c5.607-3.766,9.475-11.213,9.475-17.908c0-9.453-7.66-17.122-17.117-17.122c-9.459,0-17.11,7.669-17.11,17.122
        c0,6.695,3.861,14.148,9.46,17.908C66.451,85.145,56.504,96.793,56.504,105.415z M86.11,86.709h-0.073l-2.832-3.261
        c1.379,0.5,2.792,0.798,4.29,0.798c1.495,0,2.911-0.292,4.29-0.779l-2.844,3.249h-0.066l7.322,17.67l-8.702,8.678l-8.692-8.678
        L86.11,86.709z"/>
      <path d="M134.528,8.424c1.017,2.47,1.698,5.058,1.698,7.709v6.866c0.22,0.907,0.366,1.845,0.366,2.816v129.015
        c0,6.674-5.426,12.099-12.094,12.099H50.5c-6.665,0-12.09-5.425-12.09-12.099V25.814c0-6.67,5.425-12.096,12.09-12.096h50.555
        c0.342-2.917,1.193-5.663,2.643-8.068H50.5c-11.116,0-20.152,9.042-20.152,20.164v129.015c0,11.125,9.036,20.167,20.152,20.167
        h73.998c11.113,0,20.149-9.042,20.149-20.167V25.814C144.654,18.364,140.549,11.916,134.528,8.424z"/>
      <path d="M118.495,46.073c8.062,0,14.614-7.234,14.614-16.118V16.124c0.012-3.136-0.841-6.041-2.247-8.516l-3.428,3.428
        c0.67,1.541,1.077,3.249,1.077,5.088v13.825c0,6.36-4.5,11.518-10.017,11.523c-5.522-0.006-10.004-5.163-10.004-11.523V16.124
        c0-6.352,4.493-11.515,10.017-11.521c1.681,0.006,3.245,0.536,4.64,1.376l3.324-3.334c-2.295-1.653-5.029-2.64-7.964-2.646
        c-8.074,0-14.626,7.233-14.626,16.124l-0.007,13.831C103.875,38.839,110.433,46.067,118.495,46.073z"/>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signup = [
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
    >
      <path
        d="M0,2A2,2,0,0,1,2,0H8a2,2,0,0,1,2,2V8a2,2,0,0,1-2,2H2A2,2,0,0,1,0,8Z"
        transform="translate(4 4)"
        fill={color}
      />
      <path
        d="M2,0A2,2,0,0,0,0,2V8a2,2,0,0,0,2,2V4A2,2,0,0,1,4,2h6A2,2,0,0,0,8,0Z"
        fill={color}
      />
    </svg>,
  ];

  return (
    <>
      <div className="brand">
        {/* <img src={logo} alt="" /> */}
        <span>Whistleblowing System</span>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {canViewDashboard && (
          <Menu.Item key="nav-1">
            <NavLink to="/dashboard">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "dashboard" ? color : "",
                }}
              >
                {dashboard}
              </span>
              <span className="label label-sidenav">Dashboard</span>
            </NavLink>
          </Menu.Item>
        )}

        {(canViewCategory || canViewSubcategory || canViewPelapor || canViewTerlapor || canViewLevel || canViewLevelDetail || canViewWaktu) && (
          <Menu.Item className="menu-item-header" key="nav-2">
            Master Menu
          </Menu.Item>
        )}

        {canViewCategory && (
          <Menu.Item key="nav-3">
            <NavLink to="/kategori-laporan">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "category" ? color : "",
                }}
              >
                {category}
              </span>
              <span className="label label-sidenav">Kategori Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewSubcategory && (
          <Menu.Item key="nav-4">
            <NavLink to="/subkategori-laporan">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "subcategory" ? color : "",
                }}
              >
                {subcategory}
              </span>
              <span className="label label-sidenav">Subkategori Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewPelapor && (
          <Menu.Item key="nav-5">
            <NavLink to="/pihak-pelapor">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "target" ? color : "",
                }}
              >
                {target}
              </span>
              <span className="label label-sidenav">Pihak Pelapor</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewTerlapor && (
          <Menu.Item key="nav-6">
            <NavLink to="/pihak-terlapor">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "targetReported" ? color : "",
                }}
              >
                {targetReported}
              </span>
              <span className="label label-sidenav">Pihak Terlapor</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewLevel && (
          <Menu.Item key="nav-7">
            <NavLink to="/ruang-lingkup-terlapor">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "scope" ? color : "",
                }}
              >
                {scope}
              </span>
              <span className="label label-sidenav">Ruang Lingkup Terlapor</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewLevelDetail && (
          <Menu.Item key="nav-8">
            <NavLink to="/detail-ruang-lingkup-terlapor">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "scopeDetail" ? color : "",
                }}
              >
                {scopeDetail}
              </span>
              <span className="label label-sidenav">Detail Ruang Lingkup Terlapor</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewWaktu && (
          <Menu.Item key="nav-9">
            <NavLink to="/waktu">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "waktu" ? color : "",
                }}
              >
                {waktu}
              </span>
              <span className="label label-sidenav">Waktu Penanganan Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        {(canViewUsers || canViewRoleTask || canViewRole) && (
          <Menu.Item className="menu-item-header" key="nav-10">
            Menu Pengguna
          </Menu.Item>
        )}

        {canViewUsers && (
          <Menu.Item key="nav-11">
            <NavLink to="/pengguna">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "users" ? color : "",
                }}
              >
                {users}
              </span>
              <span className="label label-sidenav">Pengguna</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewRole && (
          <Menu.Item key="nav-13">
            <NavLink to="/role">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "role" ? color : "",
                }}
              >
                {role}
              </span>
              <span className="label label-sidenav">Role</span>
            </NavLink>
          </Menu.Item>
        )}

        {(canViewReport || canViewAllReport || canViewPersonalData || canViewAction) && (
          <Menu.Item className="menu-item-header" key="nav-14">
            Laporan
          </Menu.Item>
        )}

        {canViewReport && (
          <Menu.Item key="nav-15">
            <NavLink to="/laporan">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "report" ? color : "",
                }}
              >
                {report}
              </span>
              <span className="label label-sidenav">Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewAllReport && (
          <Menu.Item key="nav-16">
            <NavLink to="/semua-laporan">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "allReport" ? color : "",
                }}
              >
                {report}
              </span>
              <span className="label label-sidenav">Semua Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewPersonalData && (
          <Menu.Item key="nav-17">
            <NavLink to="/data-diri-pelapor">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "dataDiri" ? color : "",
                }}
              >
                {dataDiri}
              </span>
              <span className="label label-sidenav">Data Diri Pelapor</span>
            </NavLink>
          </Menu.Item>
        )}

        {canViewAction && (
          <Menu.Item key="nav-18">
            <NavLink to="/tindak-lanjut">
              <span
                className="icon icon-sidenav"
                style={{
                  background: page === "action" ? color : "",
                }}
              >
                {action}
              </span>
              <span className="label label-sidenav">Tindak Lanjut Laporan</span>
            </NavLink>
          </Menu.Item>
        )}

        <Menu.Item className="menu-item-header" key="nav-19">
          Halaman Akun
        </Menu.Item>
        {/* <Menu.Item key="nav-20">
          <NavLink to="/sign-in">
            <span className="icon icon-sidenav">{logOut}</span>
            <span className="label label-sidenav">Logout</span>
          </NavLink>
        </Menu.Item> */}
        <Menu.Item key="nav-21">
          <a onClick={handleLogout}>
            <span className="icon icon-sidenav">{signin}</span>
            <span className="label label-sidenav">Keluar</span>
          </a>
        </Menu.Item>
      </Menu>
    </>
  );
}

export default Sidenav;
