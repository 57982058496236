import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const Time = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [time, setTime] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentTime, setCurrentTime] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [selectedKeterangan, setSelectedKeterangan] = useState("");

  const [filteredTime, setFilteredTime] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalTime, setOriginalTime] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewWaktu = storedPrivileges.includes("VIEW_WAKTU");
  const canAddWaktu = storedPrivileges.includes("ADD_WAKTU");
  const canUpdateWaktu = storedPrivileges.includes("UPDATE_WAKTU");
  const canRemoveWaktu = storedPrivileges.includes("DELETE_WAKTU");

  const { Option } = Select;

  const showModal = (time) => {
    setCurrentTime(time); // Set the selected time
    form.setFieldsValue({
      keterangan: time.keterangan,
      waktu: time.waktu,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentTime(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/time/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  waktuID: record.waktuID, // Use the record's time
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Waktu Penanganan");
              message.success("Berhasil Menghapus Waktu Penanganan");

              // Refresh the time list after successful deletion
              const data = await getTime();
              setTime(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Waktu Penanganan :", error);
              message.error("Gagal Menghapus Waktu Penanganan");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/time/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          waktuID: currentTime.waktuID,
          keterangan: selectedKeterangan,
          waktu: parseInt(values.waktu),
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Waktu Already Exists") {
        message.error("Waktu Penanganan Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Waktu Penanganan");
        message.success("Berhasil Memperbarui Waktu Penanganan");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getTime().then((data) => {
          setTime(data);
          setOriginalTime(data); // Update originalTime with the new data
          setFilteredTime(data); // Update filteredTime with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Waktu Penanganan :", error);
    }
  };

  const handleAdd = () => {
    setCurrentTime(null); // Clear current time
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/time/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          keterangan: selectedKeterangan,
          waktu: parseInt(values.waktu),
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Waktu Already Exists") {
        message.error("Waktu Penanganan Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Waktu Penanganan");
        message.success("Berhasil Menambahkan Waktu Penanganan");
        setAddVisible(false);
        // Refresh the subcategory list after successful update
        getTime().then((data) => {
          setTime(data);
          setOriginalTime(data); // Update originalTime with the new data
          setFilteredTime(data); // Update filteredTime with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Waktu Penanganan :", error);
    }
  };

  const getTime = async () => {
    try {
      const response = await fetch(BASE_URL + "/time/list", {
        method: "GET",
        headers: configApi,
      });
      console.log({
        method: "GET",
        headers: configApi,
      })

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getTime().then((data) => {
      const initialTime = data || [];
      setTime(initialTime);
      setOriginalTime(initialTime);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalTime !== null) {
      const filteredData = originalTime.filter((time) =>
        time.keterangan.toLowerCase().includes(searchText.toLowerCase()) ||
        time.waktu.toString().includes(searchText.toLowerCase())
      );

      setTime(filteredData);
    }
  }, [searchText, originalTime]);

  const columns = [
    {
      title: "#",
      dataIndex: "waktuID", // You can adjust this based on your API response structure
      key: "waktuID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Keterangan",
      dataIndex: "keterangan", // Adjust this based on your API response structure
      key: "keterangan",
    },
    {
      title: "Waktu Penanganan (hari)",
      dataIndex: "waktu", // Adjust this based on your API response structure
      key: "waktu",
    },
  ];

  if (canUpdateWaktu || canRemoveWaktu) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateWaktu && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveWaktu && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {/* {canAddWaktu && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Waktu Penanganan</Button></Col>
          )} */}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {time && time.length > 0 && canViewWaktu ? (
          <Table
            dataSource={time}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: time.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>
      {/* Modal for Adding Time */}
      {canAddWaktu && (
        <Modal
          title="Tambah Waktu Penanganan"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Keterangan"
              name="keterangan"
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih keterangan!",
                },
              ]}
            >
              {/* <Input /> */}
              <Select
                placeholder="Silahkan pilih keterangan"
                onChange={(value) => setSelectedKeterangan(value)} // Update the state with selected status
              >
                <Option value="Menunggu Status Laporan">Menunggu Status Laporan</Option>
                <Option value="Menunggu Isi Data Diri">Menunggu Isi Data Diri</Option>
                <Option value="Menunggu Terbukti/Tidak">Menunggu Terbukti/Tidak</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Waktu Penanganan"
              name="waktu"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan waktu penanganan (hari)!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateWaktu && (
        <Modal
          title="Perbarui Waktu Penanganan"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Keterangan"
              name="keterangan"
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih keterangan!",
                },
              ]}
            >
              {/* <Input /> */}
              <Select
                placeholder="Silahkan pilih keterangan"
                onChange={(value) => setSelectedKeterangan(value)} // Update the state with selected status
                disabled
              >
                <Option value="Menunggu Status Laporan">Menunggu Status Laporan</Option>
                <Option value="Menunggu Isi Data Diri">Menunggu Isi Data Diri</Option>
                <Option value="Menunggu Terbukti/Tidak">Menunggu Terbukti/Tidak</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Waktu Penanganan"
              name="waktu"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan waktu penanganan (hari)!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Time;
