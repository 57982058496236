import React, { useEffect, useState, useRef } from "react";
import { Layout, Button, Row, Col, Typography, Form, Input, Card } from "antd";
import signinbg from "../assets/images/whistleblowing 2.png";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../config";
import { useHistory } from "react-router-dom";
import { getGoogleUrl } from "../lib/getGoogleUrl";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { setCookie } from "../lib/setCookies";
import Cookies from "js-cookie";
import { Google } from "@mui/icons-material";
import { googleLogout, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import GoogleButton from "react-google-button";
import axios from "axios";
import '../assets/styles/home.css';
import { FaGoogle } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";

const { Title } = Typography;
const { Content } = Layout;

const SignIn = () => {
  const from = "/sign-in";
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState([]);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [privilegeRole, setPrivilegeRole] = useState([]);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(null);

  const notify = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const notifyError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleSignInClick = (privileges) => {
    const roleTasks = privileges.map((privilege) => privilege.roleTask);

    if (!roleTasks.includes("VIEW_DASHBOARD")) {
      history.push("/kategori-laporan");
    } else {
      history.push("/dashboard");
    }
  };

  async function getUserByEmail(email) {
    try {
      const response = await fetch(BASE_URL + "/users/get-by-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email
        }), // Assuming your server expects a JSON body with the username
      });

      // console.log("email :: ", email)
      if (response.ok) {
        const user = await response.json();
        return user;
      } else {
        notifyError("Email tidak ditemukan di database");
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async function getUserByUsername(username) {
    try {
      const response = await fetch(BASE_URL + "/users/get-by-username", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username
        }), // Assuming your server expects a JSON body with the username
      });

      console.log("username :: ", username)
      if (response.ok) {
        const user = await response.json();
        return user;
      } else {
        notifyError("Username tidak ditemukan di database");
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log('Login Gagal :', error)
  });

  const handleSuccessLogin = (token, userFromDatabase) => {
    const privileges = userFromDatabase.result.privilege;
    const levelDetail = userFromDatabase.result.levelDetail;
    const allReport = userFromDatabase.result.allReport;

    const roleTasks = privileges.map((privilege) => privilege.roleTask);
    const levelDetails = levelDetail.map((levelDetail) => levelDetail.levelDetail);
    const allReports = allReport.map((allReport) => allReport.levelDetail);

    var currentTime = new Date();
    var expirationTime = new Date(currentTime.getTime() + 60 * 60 * 1000);

    sessionStorage.setItem("expire", expirationTime.toISOString());
    sessionStorage.setItem("accessToken", token);
    sessionStorage.setItem("email", userFromDatabase.result.email);
    sessionStorage.setItem("username", userFromDatabase.result.username);
    sessionStorage.setItem("privileges", JSON.stringify(roleTasks));
    sessionStorage.setItem("levelDetail", JSON.stringify(levelDetails));
    sessionStorage.setItem("allReport", JSON.stringify(allReports));

    if (!roleTasks.includes("VIEW_DASHBOARD")) {
      history.push("/kategori-laporan");
    } else {
      history.push("/dashboard");
    }
  }

  useEffect(() => {
    if (captchaToken === null && captchaRef.current) {
      captchaRef.current.execute();
    }
  }, [captchaToken]);

  const onManualLogin = async () => {
    try {
      let recaptchaResponse = captchaToken;
      if (!recaptchaResponse) {
        recaptchaResponse = await captchaRef.current.execute();
        setCaptchaToken(recaptchaResponse);
      }
      if (!recaptchaResponse) {
        notifyError("Please complete the CAPTCHA verification!");
        return;
      }
      console.log("recaptchaResponse: ", recaptchaResponse);

      const inputData = {
        username: username,
        password: password,
        recaptchaResponse: recaptchaResponse,
      };

      const response = await fetch(BASE_URL + "/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(inputData),
      });

      const json = await response.json();
      // console.log(json)

      if (json.responseMessage === "Berhasil Login") {
        const userFromDatabase = await getUserByUsername(username);

        if (userFromDatabase) {
          handleSuccessLogin(json.result.token, userFromDatabase)
          notify(json.responseMessage);

          const roleTasks = userFromDatabase.result.privilege.map((privilege) => privilege.roleTask);

          if (!roleTasks.includes("VIEW_DASHBOARD")) {
            history.push("/kategori-laporan");
          } else {
            history.push("/dashboard");
          }
        }
      } else {
        notifyError("Kredensial yang dimasukkan salah. Silahkan coba lagi!");
      }
    } catch (error) {
      console.error(error);
      notifyError("Kredensial yang dimasukkan salah. Silahkan coba lagi!");
    }
  };

  const onLogin = async () => {
    try {
      const user = await login();

      if (user) {
        const userFromDatabase = await getUserByEmail(user.profile.email);

        if (userFromDatabase) {
          sessionStorage.setItem("isGoogle", true);
          handleSuccessLogin(user.access_token, userFromDatabase)
          handleSignInClick(userFromDatabase.result.privilege);
          notify("Berhasil Login");
        } else {
          notifyError("Username tidak ditemukan di database. Silahkan coba lagi!");
        }
      } else {
        // Handle login failure
        // notifyError("Login failed.");
      }
    } catch (error) {
      console.error(error);
      notifyError("Gagal login");
    }
  };

  useEffect(() => {
    const cookies = Cookies.get("email-notfound")

    if (cookies) {
      toast.error("Email tidak terdaftar!")
      Cookies.remove("email-notfound")
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      let recaptchaResponse = captchaToken;
      if (!recaptchaResponse) {
        recaptchaResponse = await captchaRef.current.execute();
        setCaptchaToken(recaptchaResponse);
      }
      if (!recaptchaResponse) {
        notifyError("Please complete the CAPTCHA verification!");
        return;
      }
      console.log("recaptchaResponse: ", recaptchaResponse);
      
      if (!isCaptchaVerified) {
        notifyError("Please complete the CAPTCHA verification!");
        return;
      }

      if (user) {
        sessionStorage.setItem("google_access", user.access_token)
        try {
          const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json'
            }
          });

          const userData = response.data;

          const userFromDatabase = await getUserByEmail(userData.email);
          const username = await userFromDatabase?.result?.username

          const responseLogin = await fetch(BASE_URL + "/users/login-google", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              username: username,
              password: "whistleblowing412",
              recaptchaResponse: recaptchaResponse,
            }),
          });

          const json = await responseLogin.json();

          // console.log("userFromDatabase", userFromDatabase.result);
          if (userFromDatabase && userFromDatabase.responseCode === "00") {
            sessionStorage.setItem("isGoogle", true);
            handleSuccessLogin(json.result.token, userFromDatabase)
            setLoginSuccess(true);
            handleSignInClick(userFromDatabase.result.privilege);
            notify("Berhasil Login");
          } else if (userFromDatabase && userFromDatabase.responseCode === "22") {
            // Pengguna tidak ditemukan dalam database with responseCode "22"
            notifyError("Gagal Login. Username tidak ditemukan!");
          } else {
            // Handle other response codes or errors
            notifyError("Gagal Login. Terjadi kesalahan!");
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    if (user != null) {
      console.log(user)
      fetchData();
    }
  }, [user]);

  return (
    <Layout className="layout-default layout-dashboard" style={{ backgroundColor: "#d4eaff" }}>
      <Content className="signin" style={{ backgroundColor: "#d4eaff" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", position: "relative", overflow: "hidden", backgroundColor: "#d4eaff" }}>
          <div>
            <h1 className="title-h1">Selamat Datang di Whistleblowing System</h1>
            <p></p>
          </div>
          <Card style={{ maxWidth: 400, zIndex: "30", width: "100%" }}>
            {/* <Title className="mb-15" style={{ textAlign: "center" }} level={5}>Sign In</Title> */}
            {/* <Title className="font-regular text-muted" style={{ fontSize: 14 }}>
              Masukkan username dan password untuk sign in
            </Title> */}
            <Form onFinish={onManualLogin} layout="vertical" className="row-col">
              <Form.Item
                className="username"
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Silahkan masukkan username!",
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Silahkan masukkan password!",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                className="username"
                name="recaptchaResponse"
                rules={[
                  {
                    required: true,
                    message: "Silahkan melakukan verifikasi captcha!",
                  },
                ]}
              >
                <ReCAPTCHA
                  sitekey="6LcsnlkpAAAAAEeXovvYEWFPI3Zjdw2HmYEN5Dxt" // Replace with your site key
                  onChange={(token) => {
                    setCaptchaToken(token);
                    setIsCaptchaVerified(true); // Enable the Google sign-in button
                  }}
                  // onChange={(token) => setCaptchaToken(token)}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Sign In
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type="secondary"
                  style={{ width: "100%" }}
                  onClick={onLogin}
                  disabled={!isCaptchaVerified}
                >
                  <FaGoogle />
                  Sign In dengan Google
                </Button>
              </Form.Item>
              <h12 style={{ color: 'red' }}>Silahkan melakukan verifikasi captcha sebelum login google!</h12>
            </Form>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default SignIn;