import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { IoFilter } from "react-icons/io5";
import { getApiConfig } from "../lib/getApiConfig";

const Subcategory = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [subcategories, setSubcategories] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentSubcategory, setCurrentSubcategory] = useState(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredSubcategories, setFilteredSubcategories] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalSubcategories, setOriginalSubcategories] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewSubcategory = storedPrivileges.includes("VIEW_SUBKATEGORI_LAPORAN");
  const canAddSubcategory = storedPrivileges.includes("ADD_SUBKATEGORI_LAPORAN");
  const canUpdateSubcategory = storedPrivileges.includes("UPDATE_SUBKATEGORI_LAPORAN");
  const canRemoveSubcategory = storedPrivileges.includes("DELETE_SUBKATEGORI_LAPORAN");

  const [filterOptions, setFilterOptions] = useState({
    kodeKategori: selectedCategory,
    // selectedCategory: "",
    // selectedSubcategory: "",
    // selectedLevel: "",
    // selectedLevelDetail: "",
    // selectedReporter: "",
    // selectedReported: "",
    // selectedStatus: "",
  });

  let filteredReport = [];
  if (subcategories && Array.isArray(subcategories)) {
    filteredReport = subcategories.filter((item) => {
      // Implement your filtering logic here using filterOptions
      const {
        category,
      } = item;

      return (
        (!selectedCategory || selectedCategory === category)
      );
    });
  }

  const showModal = (subcategory) => {
    setCurrentSubcategory(subcategory); // Set the selected subcategory
    form.setFieldsValue({
      kodeKategori: subcategory.kodeKategori,
      kodeSubkategori: subcategory.kodeSubkategori,
      namaSubkategori: subcategory.namaSubkategori,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentSubcategory(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/subreport-category/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  kodeSubkategori: record.kodeSubkategori, // Use the record's kodeSubkategori
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Subkategori Laporan");
              message.success("Berhasil Menghapus Subkategori Laporan");

              // Refresh the category list after successful deletion
              const data = await getSubkategoriLaporan();
              setSubcategories(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Subkategori Laporan:", error);
              message.error("Gagal Menghapus Subkategori Laporan");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const getKategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/report-category/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  // console.log("currentCategory ::", currentSubcategory)

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/subreport-category/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          kodeSubkategori: currentSubcategory.kodeSubkategori,
          namaSubkategori: values.namaSubkategori,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Subkategori Laporan Already Exists") {
        message.error("Subkategori Laporan Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Subkategori Laporan");
        message.success("Berhasil Memperbarui Subkategori Laporan");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getSubkategoriLaporan().then((data) => {
          setSubcategories(data);
          setOriginalSubcategories(data); // Update originalSubcategories with the new data
          setFilteredSubcategories(data); // Update filteredSubcategories with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Subkategori Laporan :", error);
      message.error("Gagal Memperbarui Subkategori Laporan");
    }
  };

  const handleAdd = () => {
    setSelectedCategory(""); // Clear the selected category
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/subreport-category/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          kodeKategori: selectedCategory,
          namaSubkategori: values.namaSubkategori,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Subkategori Laporan Already Exists") {
        message.error("Subkategori Laporan Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Subkategori Laporan");
        message.success("Berhasil Menambahkan Subkategori Laporan");
        setAddVisible(false);
        // Refresh the subcategory list after successful addition
        getSubkategoriLaporan().then((data) => {
          setSubcategories(data);
          setOriginalSubcategories(data); // Update originalSubcategories with the new data
          setFilteredSubcategories(data); // Update filteredSubcategories with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Subkategori Laporan :", error);
      message.error("Gagal Menambahkan Subkategori Laporan");
    }
  };

  const getSubkategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/subreport-category/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      kodeKategori: selectedCategory,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/subreport-category/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setSubcategories(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  useEffect(() => {
    getSubkategoriLaporan().then((data) => {
      const initialSubcategories = data || [];
      setSubcategories(initialSubcategories);
      setOriginalSubcategories(initialSubcategories);
    });

    getKategoriLaporan().then((data) => {
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalSubcategories !== null) {
      const filteredData = originalSubcategories.filter((category) =>
        category.namaKategori?.toLowerCase().includes(searchText.toLowerCase()) ||
        category.kodeSubkategori?.toLowerCase().includes(searchText.toLowerCase()) ||
        category.namaSubkategori?.toLowerCase().includes(searchText.toLowerCase())
      );

      setSubcategories(filteredData);
    }
  }, [searchText, originalSubcategories]);

  const columns = [
    {
      title: "#",
      dataIndex: "subkategoriID", // You can adjust this based on your API response structure
      key: "subkategoriID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Nama Kategori Laporan",
      dataIndex: "namaKategori", // Adjust this based on your API response structure
      key: "namaKategori",
    },
    {
      title: "Kode Subkategori Laporan",
      dataIndex: "kodeSubkategori", // Adjust this based on your API response structure
      key: "kodeSubkategori",
    },
    {
      title: "Nama Subkategori Laporan",
      dataIndex: "namaSubkategori", // Adjust this based on your API response structure
      key: "namaSubkategori",
    },
  ];

  if (canUpdateSubcategory || canRemoveSubcategory) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateSubcategory && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveSubcategory && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <Card style={{ marginBottom: '24px' }}>
        <p style={{ opacity: .6, fontWeight: "bold" }}>Filter Data</p>
        <div className="row g-3">
          <div className="d-grid four">
            <div style={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Kategori Laporan</label>
              <div style={{ display: "flex", alignItems: "center", gap: "8px", width: "100%", flexGrow: '1' }}>
                <Select
                  placeholder="Semua"
                  className="px-4"
                  style={{ width: "100%", flexGrow: '1' }}
                  onChange={(value) => setSelectedCategory(value)}
                // style={{ width: '100%', maxWidth: '150px' }} // Adjust the maxWidth value as needed
                // dropdownStyle={{ maxHeight: '150px' }}
                >
                  <Select.Option value=""> All </Select.Option>
                  {categories && categories.length > 0 ? (
                    categories.map((category) => (
                      <Select.Option
                        key={category.kodeKategori}
                        value={category.kodeKategori}
                      >
                        {category.namaKategori}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silahkan Mengisi Tabel Kategori Laporan!</Select.Option>
                  )}
                </Select>
                <Button type="primary" onClick={handleFilter}>
                  <IoFilter />
                  Filter
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddSubcategory && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Subkategori Laporan</Button> </Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>


        {subcategories && subcategories.length > 0 && canViewSubcategory ? (
          <Table
            dataSource={subcategories}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: subcategories.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>

      {/* Modal for Adding Categories */}
      {canAddSubcategory && (
        <Modal
          title="Tambah Subkategori Laporan"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Nama Kategori Laporan"
              name="kodeKategori" // Change to the appropriate field name for the selected category
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih kategori laporan!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih kategori laporan"
                onChange={(value) => setSelectedCategory(value)}
              >
                {categories && categories.length > 0 ? (
                  categories.map((category) => (
                    <Select.Option key={category.kodeKategori} value={category.kodeKategori}>
                      {category.namaKategori}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option value="0">Silahkan Mengisi Tabel Kategori!</Select.Option>
                )}

                {/* {categories.map((category) => (
                <Select.Option key={category.kodeKategori} value={category.kodeKategori}>
                  {category.namaKategori}
                </Select.Option>
              ))} */}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nama Subkategori Laporan"
              name="namaSubkategori"
              rules={[
                {
                  required: true,
                  message: "Silahkan mengisi nama subkategori laporan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateSubcategory && (
        <Modal
          title="Perbarui Subkategori Laporan"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Kode Subkategori Laporan"
              name="kodeSubkategori"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Nama Subkategori Laporan"
              name="namaSubkategori"
              rules={[
                {
                  required: true,
                  message: "Silahkan mengisi nama subkategori laporan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Subcategory;
