import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const Category = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [originalCategories, setOriginalCategories] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewCategory = storedPrivileges.includes("VIEW_KATEGORI_LAPORAN");
  const canAddCategory = storedPrivileges.includes("ADD_KATEGORI_LAPORAN");
  const canUpdateCategory = storedPrivileges.includes("UPDATE_KATEGORI_LAPORAN");
  const canRemoveCategory = storedPrivileges.includes("DELETE_KATEGORI_LAPORAN");

  const showModal = (category) => {
    setCurrentCategory(category); // Set the selected category
    form.setFieldsValue({
      kodeKategori: category.kodeKategori,
      namaKategori: category.namaKategori,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentCategory(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/report-category/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  kodeKategori: record.kodeKategori, // Use the record's kodeKategori
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Kategori Laporan");
              message.success("Berhasil Menghapus Kategori Laporan");

              // Refresh the category list after successful deletion
              const data = await getKategoriLaporan();
              setCategories(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Kategori Laporan :", error);
              message.error("Gagal Menghapus Kategori Laporan");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Penghapusan dibatalkan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal saat mengkonfirmasi :", error);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report-category/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          kodeKategori: currentCategory.kodeKategori,
          namaKategori: values.namaKategori,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Kategori Laporan Already Exists") {
        message.error("Kategori Laporan Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Kategori Laporan");
        message.success("Berhasil Memperbarui Kategori Laporan");
        setUpdateVisible(false);
        // Refresh the category list after successful update
        getKategoriLaporan().then((data) => {
          setCategories(data);
          setOriginalCategories(data); // Update originalCategories with the new data
          setFilteredCategories(data); // Update filteredCategories with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Kategori Laporan :", error);
      message.error("Gagal Memperbarui Kategori Laporan");
    }
  };

  const handleAdd = () => {
    setCurrentCategory(null); // Clear current category
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report-category/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          namaKategori: values.namaKategori,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Kategori Laporan Already Exists") {
        message.error("Kategori Laporan Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Kategori Laporan");
        message.success("Berhasil Menambahkan Kategori Laporan");

        setAddVisible(false);

        getKategoriLaporan().then((data) => {
          setCategories(data);
          setOriginalCategories(data); // Update originalCategories with the new data
          setFilteredCategories(data); // Update filteredCategories with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Kategori Laporan :", error);
      message.error("Gagal Menambahkan Kategori Laporan");
    }
  };

  const getKategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/report-category/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };
  useEffect(() => {
    // Fetch and set initial categories
    getKategoriLaporan().then((data) => {
      const initialCategories = data || [];
      setCategories(initialCategories);
      setOriginalCategories(initialCategories);
    });
  }, []);

  // Define additional useEffect hooks for other functionality
  useEffect(() => {
    // Implement search filtering here
    const filteredData = originalCategories.filter((category) =>
    (category.kodeKategori?.toLowerCase().includes(searchText.toLowerCase()) ||
      category.namaKategori?.toLowerCase().includes(searchText.toLowerCase()))
    );
    setCategories(filteredData);
  }, [searchText, originalCategories]);

  const columns = [
    {
      title: "#",
      dataIndex: "kategoriID",
      key: "kategoriID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Kode Kategori Laporan",
      dataIndex: "kodeKategori",
      key: "kodeKategori",
    },
    {
      title: "Nama Kategori Laporan",
      dataIndex: "namaKategori",
      key: "namaKategori",
    },
  ];

  if (canUpdateCategory || canRemoveCategory) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateCategory && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveCategory && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }


  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddCategory && (
            <Col>
              <Button type="primary" onClick={handleAdd}>Tambah Kategori Laporan</Button>
            </Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />} // Menambahkan ikon search sebagai prefix
                style={{ width: '200px' }}
              />
            </div>
          </Col>
        </Row>

        {categories && categories.length > 0 && canViewCategory ? (
          <Table
            dataSource={categories}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: categories.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false}
          />
        )}
      </Card>

      {/* Modal for Adding Categories */}
      {canAddCategory && (
        <Modal
          title="Tambah Kategori Laporan"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Nama Kategori Laporan"
              name="namaKategori"
              rules={[
                {
                  required: true,
                  message: "Silahkan menambahkan nama kategori laporan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateCategory && (
        <Modal
          title="Perbarui Kategori Laporan"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Kode Kategori Laporan"
              name="kodeKategori"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Nama Kategori Laporan"
              name="namaKategori"
              rules={[
                {
                  required: true,
                  message: "Silahkan menambahkan nama kategori laporan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Category;