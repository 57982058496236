// App.js

import React from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Category from "./pages/Category";
import Subcategory from "./pages/Subcategory";
import Target from "./pages/Target";
import TargetReported from "./pages/TargetReported";
import Users from "./pages/Users";
import RoleTask from "./pages/RoleTask";
import Role from "./pages/Role";
import Action from "./pages/Action";
import Report from "./pages/Laporan";
import AllReport from "./pages/LaporanAll";
import Scope from "./pages/Scope";
import ScopeDetail from "./pages/ScopeDetail";
import Waktu from "./pages/Waktu";
import DataDiri from "./pages/DataDiri";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { AlterRouteGuard, RouteGuard } from "./lib/RouteGuard";
import { ToastContainer } from "react-toastify";

function App() {
  const history = useHistory();

  return (
    <div className="App">
      <ToastContainer />
      <Switch>
        <AlterRouteGuard path="/sign-up" exact component={SignUp} />
        <AlterRouteGuard path="/sign-in" exact component={SignIn} />
        <Main>
          <RouteGuard exact path="/" component={Home} />
          <RouteGuard exact path="/dashboard" component={Home} />
          <RouteGuard exact path="/tables" component={Tables} />
          <RouteGuard exact path="/rtl" component={Rtl} />
          <RouteGuard exact path="/profile" component={Profile} />
          <RouteGuard exact path="/kategori-laporan" component={Category} />
          <RouteGuard exact path="/subkategori-laporan" component={Subcategory} />
          <RouteGuard exact path="/pihak-pelapor" component={Target} />
          <RouteGuard exact path="/pengguna" component={Users} />
          <RouteGuard exact path="/roleTask" component={RoleTask} />
          <RouteGuard exact path="/role" component={Role} />
          <RouteGuard exact path="/tindak-lanjut" component={Action} />
          <RouteGuard exact path="/laporan" component={Report} />
          <RouteGuard exact path="/semua-laporan" component={AllReport} />
          <RouteGuard exact path="/pihak-terlapor" component={TargetReported} />
          <RouteGuard exact path="/ruang-lingkup-terlapor" component={Scope} />
          <RouteGuard exact path="/detail-ruang-lingkup-terlapor" component={ScopeDetail} />
          <RouteGuard exact path="/waktu" component={Waktu} />
          <RouteGuard exact path="/data-diri-pelapor" component={DataDiri} />
          {/* <Redirect from="*" to="/sign-in" /> */}
        </Main>
      </Switch>
    </div>
  );
}

export default App;
