import React, { useState, useEffect, useMemo } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, message, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { BASE_URL } from "../config";
import { DatePicker } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { renderAttachment } from "../components/renderAttachment";
import { IoFilter } from "react-icons/io5";
import { getApiConfig } from "../lib/getApiConfig";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const { RangePicker } = DatePicker;

const Laporan = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const configApi = getApiConfig()

  const [report, setReport] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentReport, setCurrentReport] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedDateTo, setSelectedDateTo] = useState("");

  const [selectedDateFrom, setSelectedDateFrom] = useState("");

  const [selectedDataDiri, setSelectedDataDiri] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const [reporter, setReporter] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState(0);

  const [reported, setReported] = useState([]);
  const [selectedReported, setSelectedReported] = useState(0);

  const [level, setLevel] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(0);

  const [levelDetail, setLevelDetail] = useState([]);
  const [selectedLevelDetail, setSelectedLevelDetail] = useState("");

  const [searchText, setSearchText] = useState("");

  const [allLevels, setAllLevels] = useState([]);

  const [originalReports, setOriginalReports] = useState([]);

  const [isDescriptionShow, setIsDescriptionShow] = useState(false)

  const [isLampiranShow, setIsLampiranShow] = useState(false)

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const { Option } = Select;

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewReport = storedPrivileges.includes("VIEW_REPORT");
  const canUpdateReport = storedPrivileges.includes("UPDATE_REPORT");

  const defaultDate = moment().subtract(30, 'days');
  const today = moment();

  const [dates, setDates] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 30;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    kodeKategori: selectedCategory,
    kodeSubkategori: selectedSubcategory,
    pihakPelaporID: selectedReporter,
    pihakTerlaporID: selectedReported,
    tingkatanID: selectedLevel,
    tingkatanDetail2: selectedLevelDetail,
    status: selectedStatus,
    dateTo: selectedDateTo,
    dateFrom: selectedDateFrom,
  });

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      kodeKategori: selectedCategory,
      kodeSubkategori: selectedSubcategory,
      pihakPelaporID: selectedReporter,
      pihakTerlaporID: selectedReported,
      tingkatanID: selectedLevel,
      tingkatanDetail2: selectedLevelDetail,
      laporanStatus: selectedStatus,
      dateTo: selectedDateTo,
      dateFrom: selectedDateFrom,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setReport(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  let filteredReport = [];
  if (report && Array.isArray(report)) {
    filteredReport = report.filter((item) => {
      // Implement your filtering logic here using filterOptions
      const {
        category,
        subcategory,
        level,
        levelDetail,
        reported,
        reporter,
        status,
        dateTo,
        dateFrom,
      } = item;

      return (
        (!selectedCategory || selectedCategory === category) &&
        (!selectedSubcategory || selectedSubcategory === subcategory) &&
        (!selectedLevel || selectedLevel === level) &&
        (!selectedLevelDetail || selectedLevelDetail === levelDetail) &&
        (!selectedReported || selectedReported === reported) &&
        (!selectedReporter || selectedReporter === reporter) &&
        (!selectedStatus || selectedStatus === status) &&
        (!selectedDateTo || selectedDateTo === dateTo) &&
        (!selectedDateFrom || selectedDateFrom === dateFrom)
      );
    });
  }

  const getKategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/report-category/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getSubkategoriLaporan = async (value) => {
    try {
      const response = await fetch(BASE_URL + "/subreport-category/list-by-code-category", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          kodeKategori: value.toString(),
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();

      setSubcategories(data.result)
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getPelapor = async () => {
    try {
      const response = await fetch(BASE_URL + "/target/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getTerlapor = async () => {
    try {
      const response = await fetch(BASE_URL + "/target-reported/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getLevel = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getLevelDetail = async (value) => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list-by-scope", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatan: value.toString(),
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      setLevelDetail(data.result)
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getKategoriLaporan().then((data) => {
      setCategories(data);
    });

    getPelapor().then((data) => {
      setReporter(data);
    });

    getTerlapor().then((data) => {
      setReported(data);
    });

    getLevel().then((data) => {
      setLevel(data);
    });
  }, []);


  const showModal = (report) => {
    setCurrentReport(report); // Set the selected report
    form.setFieldsValue({
      kodeLaporan: report.kodeLaporan,
      status: report.status,
      dataDiri: report.dataDiri,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const showDescModal = (report) => {
    setCurrentReport(report);
    setIsDescriptionShow(true);
  };

  const showLampiranModal = (report) => {
    setCurrentReport(report);
    setIsLampiranShow(true);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const onFinish = async (values) => {
    const handleFinish = async () => {
      try {
        const response = await fetch(BASE_URL + "/report/edit", {
          method: "POST",
          headers: configApi,
          body: JSON.stringify({
            kodeLaporan: currentReport.kodeLaporan,
            status: values.status,
            dataDiri: values.dataDiri,
          }),
        });

        if (!response.ok) {
          throw new Error("Respons jaringan tidak baik");
        }

        console.log("Berhasil Memperbarui Laporan");
        message.success("Berhasil Memperbarui Laporan");
        setUpdateVisible(false);
        // Refresh the report list after successful update
        getReport().then((data) => {
          setReport(data);
          setOriginalReports(data);
        });
      } catch (error) {
        console.error("Terjadi kesalahan saat memperbarui laporan :", error);
        message.error("Gagal Memperbarui Laporan");
      }
    }

    if (values.status === "Diproses") {
      toast.error("Silahkan pilih status", {
        position: toast.POSITION.TOP_CENTER,
      });
      return
    }

    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Pastikan data yang Anda isi sudah benar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, lanjutkan!",
      cancelButtonText: "Tidak",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "ant-btn ant-btn-primary",
        cancelButton: "ant-btn me-3 mr-3"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFinish()
      }
    });
  };

  const getReport = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getFilteredReport = async (filterOptions) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST", // Use POST for sending filter options
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
      return [];
    }
  };

  useEffect(() => {
    getReport().then((data) => {
      // Ensure data is not null and is an array before setting state
      if (Array.isArray(data)) {
        setReport(data);
        setOriginalReports(data);
      } else {
        // If data is null or not an array, set an empty array to avoid null errors
        setReport([]);
        setOriginalReports([]);
      }
    }).catch(error => {
      console.error('Terjadi kesalahan saat mengambil laporan :', error);
      // Handle error by setting report to an empty array
      setReport([]);
      setOriginalReports([]);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalReports !== null) {
      const filteredData = originalReports.filter((report) =>
        report.kodeLaporan.toLowerCase().includes(searchText.toLowerCase()) ||
        report.namaKategori.toLowerCase().includes(searchText.toLowerCase()) ||
        report.namaSubkategori.toLowerCase().includes(searchText.toLowerCase()) ||
        report.pekerjaanPelapor.toLowerCase().includes(searchText.toLowerCase()) ||
        report.pekerjaanTerlapor.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tingkatan.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tingkatanDetail.toLowerCase().includes(searchText.toLowerCase()) ||
        report.perihal.toLowerCase().includes(searchText.toLowerCase()) ||
        report.deskripsi.toLowerCase().includes(searchText.toLowerCase()) ||
        report.status.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tanggalKejadian.toLowerCase().includes(searchText.toLowerCase()) ||
        report.dataDiri.toLowerCase().includes(searchText.toLowerCase()) ||
        report.deadlineDataDiri.toLowerCase().includes(searchText.toLowerCase()) ||
        report.createdAt.toLowerCase().includes(searchText.toLowerCase())
      );

      setReport(filteredData);
    }
  }, [searchText, originalReports]);

  const columns = [
    {
      title: "#",
      dataIndex: "laporanID", // You can adjust this based on your API response structure
      key: "laporanID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Kode Laporan",
      dataIndex: "kodeLaporan", // Adjust this based on your API response structure
      key: "kodeLaporan",
    },
    {
      title: "Nama Kategori",
      dataIndex: "namaKategori", // Adjust this based on your API response structure
      key: "namaKategori",
    },
    {
      title: "Nama Subkategori",
      dataIndex: "namaSubkategori", // Adjust this based on your API response structure
      key: "namaSubkategori",
    },
    {
      title: "Pekerjaan Pelapor",
      dataIndex: "pekerjaanPelapor", // Adjust this based on your API response structure
      key: "pekerjaanPelapor",
    },
    {
      title: "Pekerjaan Terlapor",
      dataIndex: "pekerjaanTerlapor", // Adjust this based on your API response structure
      key: "pekerjaanTerlapor",
    },
    {
      title: "Ruang Lingkup",
      dataIndex: "tingkatan", // Adjust this based on your API response structure
      key: "tingkatan",
    },
    {
      title: "Detail Ruang Lingkup",
      dataIndex: "tingkatanDetail", // Adjust this based on your API response structure
      key: "tingkatanDetail",
    },
    {
      title: "Perihal",
      dataIndex: "perihal", // Adjust this based on your API response structure
      key: "perihal",
    },
    {
      title: "Deskripsi",
      dataIndex: "deskripsi",
      key: "deskripsi",
      render: (text, record) => (
        <a href="#" onClick={() => showDescModal(record)}>
          Lihat Deskripsi
        </a>
      )
    },
    {
      title: "Lampiran",
      render: (text, record) => (
        <a href="#" onClick={() => showLampiranModal(record)}>
          Lihat Lampiran
        </a>
      ),
      key: "lampiran",
    },
    {
      title: "Tanggal Kejadian",
      dataIndex: "tanggalKejadian", // Adjust this based on your API response structure
      key: "tanggalKejadian",
      render: (text, record) => {
        if (record.tanggalKejadian) {
          const date = new Date(record.tanggalKejadian);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: "Tanggal Dilaporkan",
      dataIndex: "createdAt", // Adjust this based on your API response structure
      // key: "createdAt",
      render: (text, record) => {
        if (record.createdAt) {
          const date = new Date(record.createdAt);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: "Status Laporan",
      dataIndex: "status", // Adjust this based on your API response structure
      key: "status",
    },
    {
      title: "Pengisian Data Diri",
      dataIndex: "dataDiri",
      key: "dataDiri",
      render: (dataDiri) => {
        if (dataDiri === "Y") {
          return (
            <span>
              Perlu <CheckCircleFilled style={{ color: 'green' }} />
            </span>
          );
        } else if (dataDiri === "N") {
          return (
            <span>
              Tidak Perlu <CloseCircleFilled style={{ color: 'red' }} />
            </span>
          );
        } else {
          return null; // Render an empty cell
        }
      }
    },
    {
      title: "Deadline Pengisian Data Diri",
      dataIndex: "deadlineDataDiri", // Adjust this based on your API response structure
      // key: "deadlineDataDiri",
      render: (text, record) => {
        if (record.deadlineDataDiri) {
          const date = new Date(record.deadlineDataDiri);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: 'Status Pengisian Data Diri',
      dataIndex: 'doneDataDiri',
      key: 'doneDataDiri',
      render: (doneDataDiri) => {
        if (doneDataDiri === "Done") {
          return (
            <span>
              Selesai <CheckCircleFilled style={{ color: 'green' }} />
            </span>
          );
        } else if (doneDataDiri === "Not Done") {
          return (
            <span>
              Tidak Bersedia <CloseCircleFilled style={{ color: 'red' }} />
            </span>
          );
        } else {
          return null; // Render an empty cell for any other value
        }
      }
    },
    {
      title: "Deadline Proses Laporan",
      dataIndex: "deadlineStatusLaporan", // Adjust this based on your API response structure
      // key: "deadlineStatusLaporan",
      render: (text, record) => {
        if (record.deadlineStatusLaporan) {
          const date = new Date(record.deadlineStatusLaporan);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: "Deadline Tindaklanjut Laporan",
      dataIndex: "deadlineDitindaklanjuti", // Adjust this based on your API response structure
      // key: "deadlineDitindaklanjuti",
      render: (text, record) => {
        if (record.deadlineDitindaklanjuti) {
          const date = new Date(record.deadlineDitindaklanjuti);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
  ];

  if (canUpdateReport) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateReport && (
            <Button type="primary" size="default" onClick={() => showModal(record)} disabled={record.status === 'Selesai' || record.status === 'Diterima' || record.status === 'Ditolak' || record.status === 'Kedaluwarsa'}>Aksi</Button>
          )}
        </Space>
      ),
    });
  }

  // Ambil data "tingkatanDetail" dari session storage
  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('levelDetail')) || [];

  // Filter laporan berdasarkan "tingkatanDetail" dari session storage
  const filteredReportsByLevelDetail = useMemo(() => {
    return (report && Array.isArray(report)) ? report.filter((item) => {
      const { tingkatanDetail } = item;
      return sessionLevelDetail.includes(item.tingkatanDetail);
    }) : [];
  }, [report, sessionLevelDetail]);

  useEffect(() => {
    const storedLevels = sessionStorage.getItem('levelDetail');
    if (storedLevels) {
      const sessionLevels = JSON.parse(storedLevels);
      console.log("Fetched Levels: ", sessionLevels); // Debugging line
      setAllLevels(sessionLevels);
    } else {
      console.log("Tidak ada tingkatan yang ditemukan dalam penyimpanan sesi."); // Debugging line
    }
  }, []);

  const [userFromDatabase2, setUserFromDatabase2] = useState(null);

  useEffect(() => {
    // Fetch user data from your database when userInfo.email changes
    async function getUserData() {
      try {
        // if (userInfo.email !== 'Guest') {
        const email = sessionStorage.getItem("email")
        console.log(email)
        const userFromDatabaseResponse = await getUserByEmail(email);
        console.log(userFromDatabaseResponse)
        if (userFromDatabaseResponse) {
          setUserFromDatabase2(userFromDatabaseResponse);
        } else {
          console.log('Tidak ada data pengguna yang dikembalikan dari API.');
        }
        // }
      } catch (error) {
        console.error('Gagal mengambil data pengguna:', error);
      }
    }

    getUserData();
  }, []);

  async function getUserByEmail(email) {
    try {
      const response = await fetch(`${BASE_URL}/users/get-by-email`, {
        method: 'POST',
        headers: configApi,
        body: JSON.stringify({
          email: email, // Use the user's email as the username
        }),
      });

      console.log('email :: ', email); // Make sure you see the correct email in the console
      if (response.ok) {
        const user = await response.json();
        return user.result; // Assuming the result contains user data
      } else {
        console.error('Pengguna tidak ditemukan dalam database.');
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  useEffect(() => {
    if (userFromDatabase2 && userFromDatabase2.roleName === 'Satgas Universitas') {
      setSelectedLevel(2);
      getLevelDetail(2)
    } else if (userFromDatabase2 && userFromDatabase2.roleName.includes("Satgas Fakultas")) {
      setSelectedLevel(3);
      getLevelDetail(3)
    }

  }, [userFromDatabase2])

  return (
    <div>
      <Card style={{ marginBottom: '24px' }}>
        <p style={{ opacity: .6, fontWeight: "bold" }}>Filter Data</p>
        <div className="row g-3">
          <div className="d-grid four">
            <div style={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Rentang waktu</label>
              <RangePicker defaultValue={[defaultDate, today]} onChange={(value) => {
                if (value) {
                  setSelectedDateFrom(value[0]); setSelectedDateTo(value[1])
                } else {
                  setSelectedDateFrom(""); setSelectedDateTo("")
                }
              }}
                changeOnBlur
                onOpenChange={onOpenChange}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  setDates(val);
                }}
              />
            </div>

            <div>
              <div class='d-flex-col'>
                <label htmlFor="">Nama Kategori</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => {
                    setSelectedCategory(value)
                    getSubkategoriLaporan(value)
                  }}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  {categories && categories.length > 0 ? (
                    categories.map((category) => (
                      <Select.Option key={category.kodeKategori} value={category.kodeKategori}>
                        {category.namaKategori}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Kategori!</Select.Option>
                  )}
                </Select>
              </div>

              <div class='d-flex-col'>
                <label htmlFor="">Nama Subkategori</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedSubcategory(value)}
                  disabled={!subcategories?.length}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  {subcategories && subcategories.length > 0 ? (
                    subcategories.map((subcategory) => (
                      <Select.Option key={subcategory.kodeSubkategori} value={subcategory.kodeSubkategori}>
                        {subcategory.namaSubkategori}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Subkategori!</Select.Option>
                  )}
                </Select>
              </div>
            </div>

            {userFromDatabase2 && (userFromDatabase2.roleName === 'Admin' || userFromDatabase2.roleName.includes("Satgas Fakultas") || userFromDatabase2.roleName === 'Satgas Universitas') &&
              (<div>
                {userFromDatabase2 && (userFromDatabase2.roleName === 'Admin')
                  && (
                    <div class='d-flex-col'>
                      <label htmlFor="">Ruang Lingkup</label>
                      <Select
                        placeholder="Semua"
                        onChange={(value) => {
                          setSelectedLevel(value);
                          getLevelDetail(value)
                        }}
                      >
                        <Select.Option value={0}> Semua </Select.Option>
                        {level && level.length > 0 ? (
                          level.map((level) => (
                            <Select.Option key={level.tingkatanID} value={level.tingkatanID}>
                              {level.tingkatan}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value="0">Silakan Isi Tabel Ruang Lingkup!</Select.Option>
                        )}
                      </Select>
                    </div>
                  )}
                {userFromDatabase2 && (userFromDatabase2.roleName === 'Admin' || userFromDatabase2.roleName === 'Satgas Universitas' || userFromDatabase2.roleName.includes("Satgas Fakultas")) && (
                  <div class='d-flex-col'>
                    <label htmlFor="">Sub Ruang Lingkup</label>
                    <Select
                      placeholder="Semua"
                      onChange={(value) => setSelectedLevelDetail(value)}
                      disabled={!levelDetail?.length}
                    >
                      <Select.Option value=""> Semua </Select.Option>
                      {levelDetail.length > 0 ? (
                        levelDetail.map((level, index) => (
                          <Select.Option key={index} value={level.tingkatanDetail}>
                            {level.tingkatanDetail}
                          </Select.Option>
                        ))
                      ) : (
                        <Select.Option value="0">Silakan Isi Tabel Detail Ruang Lingkup!</Select.Option>
                      )}
                    </Select>
                  </div>
                )}
              </div>)}

            <div>
              <div class='d-flex-col'>
                <label htmlFor="">Pekerjaan Pelapor</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedReporter(value)}
                >
                  <Select.Option value={0}> Semua </Select.Option>
                  {reporter && reporter.length > 0 ? (
                    reporter.map((reporter) => (
                      <Select.Option key={reporter.pihakID} value={reporter.pihakID}>
                        {reporter.pekerjaan}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Pihak!</Select.Option>
                  )}
                </Select>
              </div>

              <div class='d-flex-col'>
                <label htmlFor="">Pekerjaan Terlapor</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedReported(value)}
                >
                  <Select.Option value={0}> Semua </Select.Option>
                  {reported && reported.length > 0 ? (
                    reported.map((reported) => (
                      <Select.Option key={reported.pihakTerlaporID} value={reported.pihakTerlaporID}>
                        {reported.pekerjaan}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Pihak Terlapor!</Select.Option>
                  )}
                </Select>
              </div>
            </div>

            <div>
              <div class='d-flex-col'>
                <label htmlFor="">Status</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedStatus(value)}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  <Select.Option value="Belum Diproses"> Belum Diproses </Select.Option>
                  <Select.Option value="Diterima"> Diterima </Select.Option>
                  <Select.Option value="Ditolak"> Ditolak </Select.Option>
                  <Select.Option value="Kedaluwarsa"> Kedaluwarsa </Select.Option>
                  <Select.Option value="Selesai"> Selesai </Select.Option>
                </Select>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: '16px', marginTop: '12px' }}>
            <Button type="primary" onClick={handleFilter}>
              <IoFilter />
              Filter
            </Button>
          </div>
        </div>
      </Card>

      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        <Col></Col>
        <Col>
          <div className="search-bar">
            <Input
              placeholder="Mencari..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />} // Menambahkan ikon search sebagai prefix
              style={{ width: '200px' }}
            />
          </div>
        </Col>
      </Row>
      {filteredReportsByLevelDetail.length > 0 && canViewReport ? (
        <Table
          dataSource={filteredReportsByLevelDetail}
          columns={columns}
          pagination={{
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
            total: filteredReportsByLevelDetail.length,
            showSizeChanger: false,
            showQuickJumper: false,
            showLessItems: true,
          }}
          scroll={{ x: true }}
        />
      ) : (
        <Table
          dataSource={[]} // Provide an empty array as the data source when there is no data
          columns={columns}
          scroll={{ x: true }} // Disable pagination when there is no data
          pagination={false}
        />
      )}

      {canUpdateReport && (
        <Modal
          title="Perbarui Laporan"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Status"
              name="status" // Change to the appropriate field name for the selected status
              rules={[
                {
                  required: true,
                  message: "Silahkan memilih status!",
                },
              ]}
            >
              <Select
                value={selectedStatus}
                placeholder="Silahkan memilih status"
                onChange={(value) => setSelectedStatus(value)} // Update the state with selected status
                options={
                  [{
                    value: "Diterima",
                    label: "Diterima"
                  }, {
                    value: "Ditolak",
                    label: "Ditolak"
                  },]
                }
              >
              </Select>
            </Form.Item>
            <Form.Item
              label="Data Diri"
              name="dataDiri"
              placeholder="Data diri"
              rules={[
                {
                  required: selectedStatus === 'Diterima',
                  message: "Silahkan memilih data diri!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan memilih konfirmasi data diri"
                onChange={(value) => setSelectedDataDiri(value)}
                disabled={selectedStatus === "Ditolak"} // Disable if "Ditolak" is selected
              >
                <Option value="Y">Perlu</Option>
                <Option value="N">Tidak Perlu</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Modal
        title="Deskripsi laporan"
        visible={isDescriptionShow}
        onOk={() => setIsDescriptionShow(false)}
        onCancel={() => setIsDescriptionShow(false)}
      >
        {currentReport?.deskripsi}
      </Modal>

      <Modal
        title="Lampiran laporan"
        visible={isLampiranShow}
        onOk={() => setIsLampiranShow(false)}
        onCancel={() => setIsLampiranShow(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {renderAttachment(currentReport?.lampiran)}
        </div>
      </Modal>
    </div>
  );
};

export default Laporan;
