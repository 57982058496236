import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Checkbox, Row, Col, Select, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const Role = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [role, setRole] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentRole, setCurrentRole] = useState(null);

  const [selectedLevel, setSelectedLevel] = useState("");

  const [roleTaskModalVisible, setRoleTaskModalVisible] = useState(false);
  const [roleTaskList, setRoleTaskList] = useState([]);
  const [roleTaskList3, setRoleTaskList3] = useState([]);
  const [roleTaskList2, setRoleTaskList2] = useState([]);
  const [selectedRoleTasks, setSelectedRoleTasks] = useState([]);
  const [isRoleTaskAssignedMap, setIsRoleTaskAssignedMap] = useState({});
  const [isRoleTaskAssignedMap2, setIsRoleTaskAssignedMap2] = useState({});

  const [roleTasksFetched, setRoleTasksFetched] = useState(false);

  const [selectedData, setSelectedData] = useState([]);
  const [_, setRefresh] = useState(Date.now())

  const [dataPrivilege, setDataPrivilege] = useState([]); //array of data for checkbox

  const [selectedRoleTasks2, setSelectedRoleTasks2] = useState(
    currentRole?.roleAssignment
      ? currentRole.roleAssignment.slice(1, -1)
      : []
  );

  const [searchText, setSearchText] = useState("");

  const [filteredRole, setFilteredRole] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalRole, setOriginalRole] = useState([]);

  const [levelDetailList3, setLevelDetailList3] = useState([]);

  const [levelDetailModalVisible, setLevelDetailModalVisible] = useState(false);
  const [levelDetailAllReportModalVisible, setLevelDetailAllReportModalVisible] = useState(false);

  const [levelDetailList2, setLevelDetailList2] = useState([]);

  const [selectedLevelDetail, setSelectedLevelDetail] = useState([]);
  const [selectedLevelDetailAllReport, setSelectedLevelDetailAllReport] = useState([]);

  const [levelDetailCRUD2, setLevelDetailCRUD2] = useState([]);
  const [levelDetailAllReportCRUD2, setLevelDetailAllReportCRUD2] = useState([]);

  const [isLevelDetailAssignedMap2, setIsLevelDetailAssignedMap2] = useState({});

  const [levelDetailCRUD, setLevelDetailCRUD] = useState([]);

  const [dataPrivilege2, setDataPrivilege2] = useState([]); //array of data for checkbox

  const { Option } = Select;

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewRole = storedPrivileges.includes("VIEW_ROLE");
  const canAddRole = storedPrivileges.includes("ADD_ROLE");
  const canUpdateRole = storedPrivileges.includes("UPDATE_ROLE");
  const canRemoveRole = storedPrivileges.includes("DELETE_ROLE");

  // const showModal = (role) => {
  //   console.log("this",role)
  //   fetchRoleTasks(); // Fetch role tasks when the modal is opened

  //   setCurrentRole(role); // Set the selected role
  //   const taskResult = role.roleAssignment.slice(1,-1).split(',')
  //   setSelectedRoleTasks(taskResult)
  //   form.setFieldsValue({
  //     roleName: role.roleName,
  //     roleAssignment: role.roleAssignment,
  //   });
  //   setUpdateVisible(true); // Show the update modal
  // };

  const showLevelDetailModal = (role) => {
    setCurrentRole(role); // Set the current role to the role you are viewing tasks for
    setSelectedLevelDetail(role.levelDetail || []); // Initialize with existing role assignments

    // notes dari joy
    const levelDetailResult = role.levelDetail.slice(1, -1).split(",") // dihilangkan {} nya dan diubah jadi array dengan split

    setLevelDetailCRUD2(levelDetailResult.map(item => parseInt(item))); // mengubah setiap data dari array menjadi INT 

    setLevelDetailModalVisible(true);
    fetchLevelDetail2(); // Fetch users tasks when the modal is opened
  };

  const showLevelDetailAllReportModal = (role) => {
    setCurrentRole(role); // Set the current role to the role you are viewing tasks for
    setSelectedLevelDetailAllReport(role.levelDetailAllReport || []); // Initialize with existing role assignments

    // notes dari joy
    const levelDetailResult = role.levelDetailAllReport.slice(1, -1).split(",") // dihilangkan {} nya dan diubah jadi array dengan split

    setLevelDetailAllReportCRUD2(levelDetailResult.map(item => parseInt(item))); // mengubah setiap data dari array menjadi INT 

    setLevelDetailAllReportModalVisible(true);
    fetchLevelDetail2(); // Fetch users tasks when the modal is opened
  };
  const closeLevelDetailModal = () => {
    setLevelDetailModalVisible(false);
    setLevelDetailCRUD2([]);
  };

  const closeLevelDetailAllReportModal = () => {
    setLevelDetailAllReportModalVisible(false);
    setLevelDetailAllReportCRUD2([]);
  };

  const fetchLevelDetail2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const levelDetailData = await response.json();

      // Create a map to store assigned status for each role task
      const assignedStatusMap = {};

      levelDetailData.result.forEach((task) => {
        // Check if the task tingkatanDetailID is included in currentRole.roleAssignment
        assignedStatusMap[task.tingkatanDetailID] =
          currentRole?.levelDetail.includes(task.tingkatanDetailID);
      });

      // Set the map in state
      setIsLevelDetailAssignedMap2(assignedStatusMap);

      let levelDetailArr = levelDetailData.result?.map((item) => {
        if (levelDetailCRUD.includes(item.tingkatanDetailID)) {
          return {
            ...item,
            checked: true,
          };
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });
      // Update the role task list
      setLevelDetailList2(levelDetailArr);
    } catch (error) {
      console.error("Kesalahan saat mengambil data role_task :", error);
    }
  };

  const fetchLevelDetail3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const levelDetailData = await response.json();
      return levelDetailData.result;
    } catch (error) {
      console.error("Gagal mengambil tingkatan detail data:", error);
    }
  };

  const [roleCRUD, setRoleCRUD] = useState([]);
  const showModal = async (role) => {
    setCurrentRole(role);

    // Inisialisasi selectedRoleTasks dengan nilai dari database (array bigint)
    const roleResult = role.roleAssignment ? role.roleAssignment.slice(1, -1).split(',').map(item => parseInt(item)) : [];
    setRoleCRUD(roleResult);

    fetchRoleTasks(roleResult); // Fetch role tasks when the modal is opened
    form.setFieldsValue({
      roleName: role.roleName,
      roleAssignment: role.roleAssignment,
    });

    setUpdateVisible(true); // Show the update modal
  };

  const [roleCRUD2, setRoleCRUD2] = useState([]);

  const showRoleTaskModal = (role) => {
    setCurrentRole(role); // Set the current role to the role you are viewing tasks for
    setSelectedRoleTasks(role.roleAssignment || []); // Initialize with existing role assignments

    // notes dari joy
    const roleResult = role.roleAssignment.slice(1, -1).split(",") // dihilangkan {} nya dan diubah jadi array dengan split

    setRoleCRUD2(roleResult.map(item => parseInt(item))); // mengubah setiap data dari array menjadi INT 

    setRoleTaskModalVisible(true);
    fetchRoleTasks2(); // Fetch role tasks when the modal is opened
  };

  const closeRoleTaskModal = () => {
    setRoleTaskModalVisible(false);
    setRoleCRUD2([]);
  };

  const handleRoleTaskChange = (e, roleTaskID) => {
    const isChecked = e.target.checked;

    setSelectedRoleTasks((prevSelectedTasks) => {
      if (isChecked) {
        // Add the task if checked
        return [...prevSelectedTasks, roleTaskID];
      } else {
        // Remove the task if unchecked
        return prevSelectedTasks.filter((id) => id !== roleTaskID);
      }
    });
  };

  const fetchRoleTasks = async (roleResult) => {
    try {
      const response = await fetch(BASE_URL + "/role-task/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const roleTaskData = await response.json();

      // Create a map to store assigned status for each role task
      const assignedStatusMap = {};

      roleTaskData.result.forEach((task) => {
        // Check if the task roleTaskID is included in roleCRUD
        assignedStatusMap[task.roleTaskID] = roleResult.includes(task.roleTaskID);
      });

      // Set the map in state
      setIsRoleTaskAssignedMap(assignedStatusMap);

      let roleTaskArr = roleTaskData.result?.map((item) => {
        return {
          ...item,
          checked: roleResult.includes(item.roleTaskID),
        };
      });

      // Update the role task list
      setRoleTaskList(roleTaskArr);
      setRefresh(Date.now());
    } catch (error) {
      console.error("Kesalahan saat mengambil data role_task :", error);
    }
  };

  const fetchRoleTasks2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/role-task/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const roleTaskData = await response.json();

      // Create a map to store assigned status for each role task
      const assignedStatusMap = {};

      roleTaskData.result.forEach((task) => {
        // Check if the task roleTaskID is included in currentRole.roleAssignment
        assignedStatusMap[task.roleTaskID] =
          currentRole?.roleAssignment.includes(task.roleTaskID);
      });

      // Set the map in state
      setIsRoleTaskAssignedMap2(assignedStatusMap);

      let roleTaskArr = roleTaskData.result?.map((item) => {
        if (roleCRUD.includes(item.roleTaskID)) {
          return {
            ...item,
            checked: true,
          };
        } else {
          return {
            ...item,
            checked: false,
          };
        }
      });
      // Update the role task list
      setRoleTaskList2(roleTaskArr);
    } catch (error) {
      console.error("Kesalahan saat mengambil data role_task :", error);
    }
  };

  const fetchRoleTasks3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/role-task/list", {
        method: "GET",
        headers: configApi,
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const roleTaskData = await response.json();
      return roleTaskData.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data role_task :", error);
    }
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleCheckboxChange = (checkedValues) => {
    const newValues = [...selectedData];
    const index = newValues.indexOf(checkedValues);

    if (index === -1) {
      newValues.push(checkedValues);
    } else {
      newValues.slice(index, 1);
    }
    setSelectedData(newValues);

    setDataPrivilege(
      roleTaskList
        .filter((task) => task.checked === true)
        .map((item) => ({ roleTask: item.roleTask }))
    );
    console.log(dataPrivilege);

    // setSelectedRoleTasks2(checkedValues);
  };

  const handleCheckBox = (e) => {
    let tempArray = [...dataPrivilege];

    if (e.target.checked === true) {
      tempArray = [
        ...dataPrivilege,
        {
          roleTask: e.target.value,
        },
      ];
    } else if (e.target.checked === false) {
      const objWithIdIndex = tempArray.findIndex(
        (obj) => obj.idTask === e.target.value
      );
      tempArray.splice(objWithIdIndex, 1);
    }

    console.log(tempArray);
    setDataPrivilege(tempArray);
  };

  const handleDelete = async (record) => {
    setCurrentRole(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/role/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  roleID: record.roleID, // Use the record's roleID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Role");
              message.success("Berhasil Menghapus Role");

              // Refresh the role list after successful deletion
              const data = await getRole();
              setRole(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Role:", error);
              message.error("Gagal Menghapus Role");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Penghapusan dibatalkan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Kesalahan saat konfirmasi :", error);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/role/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          roleID: currentRole.roleID,
          roleName: values.roleName,
          level: values.level,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Role Already Exists") {
        message.error("Role Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Role");
        message.success("Berhasil Memperbarui Role");
        setUpdateVisible(false);
        // Refresh the subcategory list after successful update
        getRole().then((data) => {
          setRole(data);
          setOriginalRole(data); // Update originalRole with the new data
          setFilteredRole(data); // Update filteredRole with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Role :", error);
      message.error("Gagal Memperbarui Role");
    }
  };

  const handleAdd = () => {
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/role/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          roleName: values.roleName,
          roleAssignment: dataPrivilege,
          level: values.level,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Role Already Exists") {
        message.error("Role Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Role");
        message.success("Berhasil Menambahkan Role");
        setAddVisible(false);
        // Refresh the subcategory list after successful update
        getRole().then((data) => {
          setRole(data);
          setOriginalRole(data); // Update originalRole with the new data
          setFilteredRole(data); // Update filteredRole with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Role:", error);
      message.error("Gagal Menambahkan Role");
    }
  };

  const getRole = async () => {
    try {
      const response = await fetch(BASE_URL + "/role/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getRole().then((data) => {
      const initialRole = data || [];
      setRole(initialRole);
      setOriginalRole(initialRole);
    });
    fetchRoleTasks3().then((data) => {
      setRoleTaskList3(data);
    });

    fetchLevelDetail3().then((data) => {
      setLevelDetailList3(data);
    });
  }, []);

  const outputArray = false;

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalRole !== null) {
      const filteredData = originalRole.filter((role) =>
        role.roleName.toLowerCase().includes(searchText.toLowerCase())
      );

      setRole(filteredData);
    }
  }, [searchText, originalRole]);

  const columns = [
    {
      title: "#",
      dataIndex: "roleID", // You can adjust this based on your API response structure
      key: "roleID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Nama Role",
      dataIndex: "roleName", // Adjust this based on your API response structure
      key: "roleName",
    },
    {
      title: "Tugas Role",
      dataIndex: "roleAssignment",
      render: (text, record) => (
        <Button type="link" onClick={() => showRoleTaskModal(record)}>
          Lihat Tugas Role
        </Button>
      ),
    },
    {
      title: "Penanganan Detail Tingkatan",
      dataIndex: "roleAssignment",
      render: (text, record) => (
        <Button type="link" onClick={() => showLevelDetailModal(record)}>
          Lihat Penanganan Detail Tingkatan
        </Button>
      ),
    },
    {
      title: "Detail Tingkatan Semua Laporan",
      dataIndex: "roleAssignment",
      render: (text, record) => (
        <Button type="link" onClick={() => showLevelDetailAllReportModal(record)}>
          Lihat Detail Tingkatan Semua Laporan
        </Button>
      ),
    },
  ];

  // Assuming roleTaskList3 contains data with a 'roleGroup' property
  const roleGroups = [...new Set(roleTaskList3.map((task) => task.roleGroup))];

  // catatan dari joy 

  const handleLevelDetailChange = (e, tingkatanDetailID) => {
    const isChecked = e.target.checked;

    setSelectedLevelDetail((prevSelectedTasks) => {
      if (isChecked) {
        // Add the task if checked
        return [...prevSelectedTasks, tingkatanDetailID];
      } else {
        // Remove the task if unchecked
        return prevSelectedTasks.filter((id) => id !== tingkatanDetailID);
      }
    });
  };

  const handleLevelDetailAllReportChange = (e, tingkatanDetailID) => {
    const isChecked = e.target.checked;

    setSelectedLevelDetailAllReport((prevSelectedTasks) => {
      if (isChecked) {
        // Add the task if checked
        return [...prevSelectedTasks, tingkatanDetailID];
      } else {
        // Remove the task if unchecked
        return prevSelectedTasks.filter((id) => id !== tingkatanDetailID);
      }
    });
  };

  const tingkatanGroups = [...new Set(levelDetailList3?.map((task) => task.tingkatan))];

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {/* {canAddRole && ( */}
          {/* <Col><Button type="primary" onClick={handleAdd}>Add Role</Button></Col> */}
          <Col></Col>
          {/* )} */}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {role && role.length > 0 && canViewRole ? (
          <Table
            dataSource={role}
            columns={columns}
            scroll={{ x: "100%" }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: role.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>
      {/* Modal for Adding Categories */}
      {canAddRole && (
        <Modal
          title="Tambah Role"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Nama Role"
              name="roleName"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan nama role!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tingkatan"
              name="level" // Change to the appropriate field name for the selected level
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih ruang lingkup!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih ruang lingkup!"
                onChange={(value) => setSelectedLevel(value)} // Update the state with selected level
              >
                <Option value="-">-</Option>
                <Option value="FAD">Fakultas Arsitektur & Desain</Option>
                <Option value="FBS">Fakultas Bahasa & Seni</Option>
                <Option value="FEB">Fakultas Ekonomi & Bisnis</Option>
                <Option value="FHK">Fakultas Hukum & Komunikasi</Option>
                <Option value="FITL">Fakultas Ilmu & Teknologi Lingkungan</Option>
                <Option value="FIKOM">Fakultas Ilmu Komputer</Option>
                <Option value="FK">Fakultas Kedokteran</Option>
                <Option value="Psikologi">Fakultas Psikologi</Option>
                <Option value="FT">Fakultas Teknik</Option>
                <Option value="FTP">Fakultas Teknologi Pertanian</Option>
                <Option value="International Undergraduate Program">International Undergraduate Program</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateRole && (
        <Modal
          title="Perbarui Role"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              label="Nama Role"
              name="roleName"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan nama role!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Tingkatan"
              name="level" // Change to the appropriate field name for the selected level
              rules={[
                {
                  required: true,
                  message: "Silahkan pilih ruang lingkup!",
                },
              ]}
            >
              <Select
                placeholder="Silahkan pilih ruang lingkup"
                onChange={(value) => setSelectedLevel(value)} // Update the state with selected level
              >
                <Option value="-">-</Option>
                <Option value="FAD">Fakultas Arsitektur & Desain</Option>
                <Option value="FBS">Fakultas Bahasa & Seni</Option>
                <Option value="FEB">Fakultas Ekonomi & Bisnis</Option>
                <Option value="FHK">Fakultas Hukum & Komunikasi</Option>
                <Option value="FITL">Fakultas Ilmu & Teknologi Lingkungan</Option>
                <Option value="FIKOM">Fakultas Ilmu Komputer</Option>
                <Option value="FK">Fakultas Kedokteran</Option>
                <Option value="Psikologi">Fakultas Psikologi</Option>
                <Option value="FT">Fakultas Teknik</Option>
                <Option value="FTP">Fakultas Teknologi Pertanian</Option>
                <Option value="International Undergraduate Program">International Undergraduate Program</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Modal
        title="Tambah Role Tasks"
        visible={roleTaskModalVisible}
        onCancel={closeRoleTaskModal}
        footer={null}
      >
        {roleGroups.map((group) => (
          <div key={group}>
            <h3>{group}</h3>
            <Row gutter={16}>
              {roleTaskList3
                .filter((task) => task.roleGroup === group)
                .map((task) => (
                  <Col span={18} key={task.roleTaskID}>
                    <Checkbox
                      disabled={true}
                      checked={roleCRUD2.includes(task.roleTaskID)}
                      onChange={(e) => handleRoleTaskChange(e, task.roleTaskID)}
                    >
                      {task.roleTask}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
      </Modal>

      <Modal
        title="Detail Ruang Lingkup Penanganan"
        visible={levelDetailModalVisible}
        onCancel={closeLevelDetailModal}
        footer={null}
      >
        {tingkatanGroups.map((group) => (
          <div key={group}>
            <h3>{group}</h3>
            <Row gutter={16}>
              {levelDetailList3
                .filter((task) => task.tingkatan === group)
                .map((task) => (
                  <Col span={18} key={task.tingkatanDetailID}>
                    <Checkbox
                      disabled={true}
                      checked={levelDetailCRUD2.includes(task.tingkatanDetailID)}
                      onChange={(e) => handleLevelDetailChange(e, task.tingkatanDetailID)}
                    >
                      {task.tingkatanDetail}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
      </Modal>

      <Modal
        title="Detail Ruang Lingkup Semua Laporan"
        visible={levelDetailAllReportModalVisible}
        onCancel={closeLevelDetailAllReportModal}
        footer={null}
      >
        {tingkatanGroups.map((group) => (
          <div key={group}>
            <h3>{group}</h3>
            <Row gutter={16}>
              {levelDetailList3
                .filter((task) => task.tingkatan === group)
                .map((task) => (
                  <Col span={18} key={task.tingkatanDetailID}>
                    <Checkbox
                      disabled={true}
                      checked={levelDetailAllReportCRUD2.includes(task.tingkatanDetailID)}
                      onChange={(e) => handleLevelDetailAllReportChange(e, task.tingkatanDetailID)}
                    >
                      {task.tingkatanDetail}
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </div>
        ))}
      </Modal>
    </div>
  );
};

export default Role;