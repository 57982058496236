import React, { useState, useEffect, useMemo } from "react";
import { Table, Space, Button, Modal, Form, Input, Select, Row, Col, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { BASE_URL } from "../config";
import { DatePicker } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { renderAttachment } from "../components/renderAttachment";
import { IoFilter } from "react-icons/io5";
import { getApiConfig } from "../lib/getApiConfig";

const { RangePicker } = DatePicker;

const LaporanAll = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [report, setReport] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentReport, setCurrentReport] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedStatusAction, setSelectedStatusAction] = useState("");

  const [selectedDateTo, setSelectedDateTo] = useState("");

  const [selectedDateFrom, setSelectedDateFrom] = useState("");

  const [selectedDataDiri, setSelectedDataDiri] = useState("");

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const [reporter, setReporter] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState(0);

  const [reported, setReported] = useState([]);
  const [selectedReported, setSelectedReported] = useState(0);

  const [level, setLevel] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(0);

  const [levelDetail, setLevelDetail] = useState([]);
  const [selectedLevelDetail, setSelectedLevelDetail] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredReports, setFilteredReports] = useState([]);

  const [originalReports, setOriginalReports] = useState([]);

  const [allLevels, setAllLevels] = useState([]);

  const [form] = Form.useForm();

  const [isDescriptionShow, setIsDescriptionShow] = useState(false)

  const [isLampiranShow, setIsLampiranShow] = useState(false)

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewAllReport = storedPrivileges.includes("VIEW_ALL_REPORT");

  // Calculate the default date as 7 days from today
  const defaultDate = moment().subtract(30, 'days');
  const today = moment();

  const [dates, setDates] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 30;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    kodeKategori: selectedCategory,
    kodeSubkategori: selectedSubcategory,
    pihakPelaporID: selectedReporter,
    pihakTerlaporID: selectedReported,
    tingkatanID: selectedLevel,
    tingkatanDetail2: selectedLevelDetail,
    status: selectedStatus,
    dateTo: selectedDateTo,
    dateFrom: selectedDateFrom,
    actionStatus: selectedStatusAction,
  });

  const handleFilter = async () => {
    // Create an object with filter options based on the selected values
    const filterOptions = {
      kodeKategori: selectedCategory,
      kodeSubkategori: selectedSubcategory,
      pihakPelaporID: selectedReporter,
      pihakTerlaporID: selectedReported,
      tingkatanID: selectedLevel,
      tingkatanDetail2: selectedLevelDetail,
      status: selectedStatus,
      dateTo: selectedDateTo,
      dateFrom: selectedDateFrom,
      actionStatus: selectedStatusAction,
    };

    // Send a request to your Go backend with filter options
    try {
      const response = await fetch(BASE_URL + "/report/list-all-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify(filterOptions),
      });

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      const data = await response.json();
      // Update the report data with the filtered results
      setReport(data.result);
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data yang difilter :", error);
    }
  };

  let filteredReport = [];
  if (report && Array.isArray(report)) {
    filteredReport = report.filter((item) => {
      // Implement your filtering logic here using filterOptions
      const {
        category,
        subcategory,
        level,
        levelDetail,
        reported,
        reporter,
        status,
        dateTo,
        dateFrom,
      } = item;

      return (
        (!selectedCategory || selectedCategory === category) &&
        (!selectedSubcategory || selectedSubcategory === subcategory) &&
        (!selectedLevel || selectedLevel === level) &&
        (!selectedLevelDetail || selectedLevelDetail === levelDetail) &&
        (!selectedReported || selectedReported === reported) &&
        (!selectedReporter || selectedReporter === reporter) &&
        (!selectedStatus || selectedStatus === status) &&
        (!selectedDateTo || selectedDateTo === dateTo) &&
        (!selectedDateFrom || selectedDateFrom === dateFrom)
      );
    });
  }

  const getKategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/report-category/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getSubkategoriLaporan = async (value) => {
    try {
      const response = await fetch(BASE_URL + "/subreport-category/list-by-code-category", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          kodeKategori: value.toString(),
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();

      setSubcategories(data.result)
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getPelapor = async () => {
    try {
      const response = await fetch(BASE_URL + "/target/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getTerlapor = async () => {
    try {
      const response = await fetch(BASE_URL + "/target-reported/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getLevel = async () => {
    try {
      const response = await fetch(BASE_URL + "/scope/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  const getLevelDetail = async (value) => {
    try {
      const response = await fetch(BASE_URL + "/scope-detail/list-by-scope", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatan: value.toString(),
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      setLevelDetail(data.result)
      // console.log(levelDetail)
      // console.log(data.result)
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getKategoriLaporan().then((data) => {
      setCategories(data);
    });

    // getSubkategoriLaporan().then((data) => {
    //   setSubcategories(data);
    // });

    getPelapor().then((data) => {
      setReporter(data);
    });

    getTerlapor().then((data) => {
      setReported(data);
    });

    getLevel().then((data) => {
      setLevel(data);
    });

    // getLevelDetail().then((data) => {
    //   setLevelDetail(data);
    // });
  }, []);

  const showModal = (report) => {
    setCurrentReport(report);
    setIsDescriptionShow(true);
  };

  const showLampiranModal = (report) => {
    setCurrentReport(report);
    setIsLampiranShow(true);
  };

  const getReport = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/list-all", {
        method: "POST",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getReport().then((data) => {
      setReport(data);
      setOriginalReports(data);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalReports !== null) {
      const filteredData = originalReports?.filter((report) =>
        report.kodeLaporan.toLowerCase().includes(searchText.toLowerCase()) ||
        report.namaKategori.toLowerCase().includes(searchText.toLowerCase()) ||
        report.namaSubkategori.toLowerCase().includes(searchText.toLowerCase()) ||
        report.pekerjaanPelapor.toLowerCase().includes(searchText.toLowerCase()) ||
        report.pekerjaanTerlapor.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tingkatan.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tingkatanDetail.toLowerCase().includes(searchText.toLowerCase()) ||
        report.perihal.toLowerCase().includes(searchText.toLowerCase()) ||
        report.deskripsi.toLowerCase().includes(searchText.toLowerCase()) ||
        report.status.toLowerCase().includes(searchText.toLowerCase()) ||
        report.tanggalKejadian.toLowerCase().includes(searchText.toLowerCase()) ||
        report.dataDiri.toLowerCase().includes(searchText.toLowerCase()) ||
        report.deadlineDataDiri.toLowerCase().includes(searchText.toLowerCase()) ||
        report.createdAt.toLowerCase().includes(searchText.toLowerCase()) ||
        report.actionStatus.toLowerCase().includes(searchText.toLowerCase()) ||
        report.actionDeskripsi.toLowerCase().includes(searchText.toLowerCase())
      );

      setReport(filteredData);
    }
  }, [searchText, originalReports]);

  const columns = [
    {
      title: "#",
      dataIndex: "laporanID", // You can adjust this based on your API response structure
      key: "laporanID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Kode Laporan",
      dataIndex: "kodeLaporan", // Adjust this based on your API response structure
      key: "kodeLaporan",
    },
    {
      title: "Nama Kategori",
      dataIndex: "namaKategori", // Adjust this based on your API response structure
      key: "namaKategori",
    },
    {
      title: "Nama Subkategori",
      dataIndex: "namaSubkategori", // Adjust this based on your API response structure
      key: "namaSubkategori",
    },
    {
      title: "Pekerjaan Pelapor",
      dataIndex: "pekerjaanPelapor", // Adjust this based on your API response structure
      key: "pekerjaanPelapor",
    },
    {
      title: "Pekerjaan Terlapor",
      dataIndex: "pekerjaanTerlapor", // Adjust this based on your API response structure
      key: "pekerjaanTerlapor",
    },
    {
      title: "Ruang Lingkup",
      dataIndex: "tingkatan", // Adjust this based on your API response structure
      key: "tingkatan",
    },
    {
      title: "Detail Ruang Lingkup",
      dataIndex: "tingkatanDetail", // Adjust this based on your API response structure
      key: "tingkatanDetail",
    },
    {
      title: "Perihal",
      dataIndex: "perihal", // Adjust this based on your API response structure
      key: "perihal",
    },
    {
      title: "Deskripsi",
      dataIndex: "deskripsi",
      key: "deskripsi",
      render: (text, record) => (
        <a href="#" onClick={() => showModal(record)}>
          Lihat Deskripsi
        </a>
      )
    },
    {
      title: "Lampiran",
      render: (text, record) => (
        <a href="#" onClick={() => showLampiranModal(record)}>
          Lihat Lampiran
        </a>
      ),
      key: "lampiran",
    },
    {
      title: "Tanggal Kejadian",
      dataIndex: "tanggalKejadian", // Adjust this based on your API response structure
      // key: "tanggalKejadian",
      render: (text, record) => {
        if (record.tanggalKejadian) {
          const date = new Date(record.tanggalKejadian);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: "Tanggal Dilaporkan",
      dataIndex: "createdAt", // Adjust this based on your API response structure
      // key: "createdAt",
      render: (text, record) => {
        if (record.createdAt) {
          const date = new Date(record.createdAt);
          return date.toLocaleDateString("id-ID", {
            day: "numeric",
            month: "long",
            year: "numeric",
          });
        }
        return null; // Handle cases where tanggalKejadian is missing or not a valid date
      },
    },
    {
      title: "Status Laporan",
      dataIndex: "status", // Adjust this based on your API response structure
      key: "status",
    },
    {
      title: "Status Tindak Lanjut Laporan",
      dataIndex: "actionStatus", // Adjust this based on your API response structure
      key: "actionStatus",
    },
    {
      title: "Deskripsi Tindakan",
      dataIndex: "actionDeskripsi", // Adjust this based on your API response structure
      key: "actionDeskripsi",
    },
  ];

  // Ambil data "tingkatanDetail" dari session storage
  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('allReport')) || [];

  // Filter laporan berdasarkan "tingkatanDetail" dari session storage
  const filteredReportsByLevelDetail = useMemo(() => {
    return (report && Array.isArray(report)) ? report.filter((item) => {
      const { tingkatanDetail } = item;
      return sessionLevelDetail.includes(item.tingkatanDetail);
    }) : [];
  }, [report, sessionLevelDetail]);

  useEffect(() => {
    const storedLevels = sessionStorage.getItem('allReport');
    if (storedLevels) {
      const sessionLevels = JSON.parse(storedLevels);
      // console.log("Fetched Levels: ", sessionLevels); // Debugging line
      setAllLevels(sessionLevels);
    } else {
      console.log("Tidak ada tingkatan yang ditemukan dalam penyimpanan sesi."); // Debugging line
    }
  }, []);

  const [userFromDatabase2, setUserFromDatabase2] = useState(null);

  useEffect(() => {
    // Fetch user data from your database when userInfo.email changes
    async function getUserData() {
      try {
        // if (userInfo.email !== 'Guest') {
        const email = sessionStorage.getItem("email")
        const userFromDatabaseResponse = await getUserByEmail(email);
        if (userFromDatabaseResponse) {
          setUserFromDatabase2(userFromDatabaseResponse);
          // console.log('User from database:', userFromDatabaseResponse);
        } else {
          // console.log('Tidak ada data pengguna yang dikembalikan dari API.');
        }
        // }
      } catch (error) {
        console.error('Gagal mengambil data pengguna:', error);
      }
    }

    getUserData();
  }, []);

  async function getUserByEmail(email) {
    try {
      const response = await fetch(`${BASE_URL}/users/get-by-email`, {
        method: 'POST',
        headers: configApi,
        body: JSON.stringify({
          email: email, // Use the user's email as the username
        }),
      });

      // console.log('email :: ', email); // Make sure you see the correct email in the console
      if (response.ok) {
        const user = await response.json();
        return user.result; // Assuming the result contains user data
      } else {
        console.error('Pengguna tidak ditemukan dalam database.');
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  return (
    <div>
      <Card style={{ marginBottom: '24px' }}>
        <p style={{ opacity: .6, fontWeight: "bold" }}>Filter Data</p>
        <div className="row g-3">
          <div className="d-grid four">
            <div style={{ gridColumn: "span 4", display: 'flex', flexDirection: 'column' }}>
              <label htmlFor="">Rentang waktu</label>
              <RangePicker defaultValue={[defaultDate, today]} onChange={(value) => {
                if (value) {
                  setSelectedDateFrom(value[0]); setSelectedDateTo(value[1])
                } else {
                  setSelectedDateFrom(""); setSelectedDateTo("")
                }
              }}
                changeOnBlur
                onOpenChange={onOpenChange}
                disabledDate={disabledDate}
                onCalendarChange={(val) => {
                  setDates(val);
                }}
              />
            </div>
            <div>
              <div className='d-flex-col'>
                <label htmlFor="">Nama Kategori</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => {
                    setSelectedCategory(value)
                    getSubkategoriLaporan(value)
                  }}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  {categories && categories.length > 0 ? (
                    categories.map((category) => (
                      <Select.Option key={category.kodeKategori} value={category.kodeKategori}>
                        {category.namaKategori}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Kategori!</Select.Option>
                  )}
                </Select>
              </div>
              <div className='d-flex-col mt-2'>
                <label htmlFor="">Nama Subkategori</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedSubcategory(value)}
                  disabled={!subcategories?.length}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  {subcategories && subcategories.length > 0 ? (
                    subcategories.map((subcategory) => (
                      <Select.Option key={subcategory.kodeSubkategori} value={subcategory.kodeSubkategori}>
                        {subcategory.namaSubkategori}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Subkategori!.</Select.Option>
                  )}
                </Select>
              </div>
            </div>
            {userFromDatabase2 && (userFromDatabase2.roleName === 'Admin' || userFromDatabase2.roleName === 'Satgas Yayasan' || userFromDatabase2.roleName === 'Satgas Universitas') && (
              <div>
                <div className='d-flex-col'>
                  <label htmlFor="">Ruang Lingkup</label>
                  <Select
                    placeholder="Semua"
                    onChange={(value) => {
                      setSelectedLevel(value);
                      getLevelDetail(value)
                    }}
                  >
                    <Select.Option value={0}> Semua </Select.Option>
                    {userFromDatabase2.roleName !== 'Satgas Universitas'}
                    {level && level.length > 0 ? (
                      level.filter(level =>
                        userFromDatabase2.roleName !== 'Satgas Universitas' ||
                        level.tingkatan === 'Fakultas' ||
                        level.tingkatan === 'Program Studi'
                      ).map((level) => (
                        <Select.Option key={level.tingkatanID} value={level.tingkatanID}>
                          {level.tingkatan}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value="0">Silakan Isi Tabel Ruang Lingkup!</Select.Option>
                    )}
                  </Select>
                </div>
                <div className='d-flex-col mt-2'>
                  <label htmlFor="">Detail Ruang Lingkup</label>
                  <Select
                    placeholder="Semua"
                    onChange={(value) => setSelectedLevelDetail(value)}
                    disabled={!levelDetail?.length}
                  >
                    <Select.Option value=""> Semua </Select.Option>
                    {levelDetail?.length > 0 ? (
                      levelDetail.map((level, index) => (
                        <Select.Option key={`lv-${index}`} value={level.tingkatanDetail}>
                          {level.tingkatanDetail}
                        </Select.Option>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </div>
              </div>
            )}

            <div>
              <div className='d-flex-col'>
                <label htmlFor="">Pekerjaan Pelapor</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedReporter(value)}
                >
                  <Select.Option value={0}> Semua </Select.Option>
                  {reporter && reporter.length > 0 ? (
                    reporter.map((reporter) => (
                      <Select.Option key={reporter.pihakID} value={reporter.pihakID}>
                        {reporter.pekerjaan}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Pihak!</Select.Option>
                  )}
                </Select>
              </div>
              <div className='d-flex-col mt-2'>
                <label htmlFor="">Pekerjaan Terlapor</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedReported(value)}
                >
                  <Select.Option value={0}> Semua </Select.Option>
                  {reported && reported.length > 0 ? (
                    reported.map((reported) => (
                      <Select.Option key={reported.pihakTerlaporID} value={reported.pihakTerlaporID}>
                        {reported.pekerjaan}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value="0">Silakan Isi Tabel Pihak Terlapor!</Select.Option>
                  )}
                </Select>
              </div>
            </div>
            <div>
              <div className='d-flex-col'>
                <label htmlFor="">Status Laporan</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedStatus(value)}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  <Select.Option value="Belum Diproses"> Belum Diproses </Select.Option>
                  <Select.Option value="Diterima"> Diterima </Select.Option>
                  <Select.Option value="Ditolak"> Ditolak </Select.Option>
                  <Select.Option value="Kedaluwarsa"> Kedaluwarsa </Select.Option>
                  <Select.Option value="Selesai"> Selesai </Select.Option>
                </Select>
              </div>
              <div className='d-flex-col mt-2'>
                <label htmlFor="">Status Tindak Lanjut Laporan</label>
                <Select
                  placeholder="Semua"
                  onChange={(value) => setSelectedStatusAction(value)}
                >
                  <Select.Option value=""> Semua </Select.Option>
                  <Select.Option value="Terbukti dan signifikan"> Terbukti dan signifikan </Select.Option>
                  <Select.Option value="Terbukti tapi dampak tidak signifikan"> Terbukti tapi dampak tidak signifikan </Select.Option>
                  <Select.Option value="Tidak Terbukti"> Tidak Terbukti </Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '16px' }}>
            <Button type="primary" onClick={handleFilter}>
              <IoFilter />
              Filter
            </Button>
          </div>
        </div>
      </Card>

      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          <Col></Col>
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />} // Menambahkan ikon search sebagai prefix
                style={{ width: '200px' }}
              />
            </div>
          </Col>
        </Row>
        {filteredReportsByLevelDetail.length > 0 && canViewAllReport ? (
          <Table
            dataSource={filteredReportsByLevelDetail}
            columns={columns}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: filteredReportsByLevelDetail.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
            scroll={{ x: true }}
          />
        ) : (
          <Table
            dataSource={[]} // Provide an empty array as the data source when there is no data
            columns={columns}
            scroll={{ x: true }} // Disable pagination when there is no data
            pagination={false}
          />
        )}
      </Card>

      <Modal
        title="Deskripsi laporan"
        visible={isDescriptionShow}
        onOk={() => setIsDescriptionShow(false)}
        onCancel={() => setIsDescriptionShow(false)}
      >
        {userFromDatabase2 && (userFromDatabase2.roleName !== 'Admin') ?
          currentReport?.deskripsi : 'iAskADknwd97ASdnasdhw2AScjAldcmcLAMnd(2rweA'
        }
      </Modal>

      <Modal
        title="Lampiran laporan"
        visible={isLampiranShow}
        onOk={() => setIsLampiranShow(false)}
        onCancel={() => setIsLampiranShow(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {renderAttachment(currentReport?.lampiran)}
        </div>
      </Modal>
    </div>
  );
};

export default LaporanAll;
