import React, { useState, useEffect } from "react";
import { Table, Space, Button, Modal, Form, Input, Row, Col, message, Card } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";

const Target = () => {
  const configApi = getApiConfig()

  const [currentPage, setCurrentPage] = useState(1);

  const [targetReported, setTargetReported] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentTargetReported, setCurrentTargetReported] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [filteredTargetReported, setFilteredTargetReported] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalTargetReported, setOriginalTargetReported] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewTerlapor = storedPrivileges.includes("VIEW_TERLAPOR");
  const canAddTerlapor = storedPrivileges.includes("ADD_TERLAPOR");
  const canUpdateTerlapor = storedPrivileges.includes("UPDATE_TERLAPOR");
  const canRemoveTerlapor = storedPrivileges.includes("DELETE_TERLAPOR");

  const showModal = (targetReported) => {
    setCurrentTargetReported(targetReported); // Set the selected targetReported
    form.setFieldsValue({
      pekerjaan: targetReported.pekerjaan,
    });
    setUpdateVisible(true); // Show the update modal
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const handleDelete = async (record) => {
    setCurrentTargetReported(record);

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: "Menghapus Data",
          icon: <ExclamationCircleOutlined />,
          content: "Apakah Anda Ingin Menghapus Data Ini?",
          okText: "Hapus",
          okType: "danger",
          cancelText: "Batal",
          onOk: async () => {
            try {
              const response = await fetch(BASE_URL + "/target-reported/remove", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  pihakTerlaporID: record.pihakTerlaporID, // Use the record's pihakID
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              console.log("Berhasil Menghapus Pihak Terlapor");
              message.success("Berhasil Menghapus Pihak Terlapor");

              // Refresh the target list after successful deletion
              const data = await getTargetReported();
              setTargetReported(data);

              resolve(); // Resolve the outer promise to indicate success
            } catch (error) {
              console.error("Gagal Menghapus Pihak Terlapor :", error);
              message.error("Gagal Menghapus Pihak Terlapor");
              reject(error); // Reject the outer promise to indicate failure
            }
          },
          onCancel: () => {
            reject(new Error("Pembatalan penghapusan")); // Reject the outer promise to indicate cancellation
          },
        });
      });
    } catch (error) {
      console.error("Gagal ketika konfirmasi :", error);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/target-reported/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          pihakTerlaporID: currentTargetReported.pihakTerlaporID,
          pekerjaan: values.pekerjaan,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Pekerjaan Already Exists") {
        message.error("Pekerjaan Sudah Ada");
      } else {
        console.log("Berhasil Memperbarui Pihak Terlapor");
        message.success("Berhasil Memperbarui Pihak Terlapor");
        setUpdateVisible(false);
        // Refresh the target list after successful update
        getTargetReported().then((data) => {
          setTargetReported(data);
          setOriginalTargetReported(data); // Update originalTargetReported with the new data
          setFilteredTargetReported(data); // Update filteredTargetReported with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Memperbarui Pihak Terlapor :", error);
      message.error("Gagal Memperbarui Pihak Terlapor");
    }
  };

  const handleAdd = () => {
    setCurrentTargetReported(null); // Clear current target
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/target-reported/add", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          pekerjaan: values.pekerjaan,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }

      if (responseData.responseMessage === "Pekerjaan Already Exists") {
        message.error("Pekerjaan Sudah Ada");
      } else {
        console.log("Berhasil Menambahkan Pihak Terlapor");
        message.success("Berhasil Menambahkan Pihak Terlapor");
        setAddVisible(false);
        // Refresh the target list after successful update
        getTargetReported().then((data) => {
          setTargetReported(data);
          setOriginalTargetReported(data); // Update originalTargetReported with the new data
          setFilteredTargetReported(data); // Update filteredTargetReported with the new data
        });
      }
    } catch (error) {
      console.error("Gagal Menambahkan Pihak Terlapor:", error);
      message.error("Gagal Menambahkan Pihak Terlapor");
    }
  };

  const getTargetReported = async () => {
    try {
      const response = await fetch(BASE_URL + "/target-reported/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getTargetReported().then((data) => {
      const initialTargetReported = data || [];
      setTargetReported(initialTargetReported);
      setOriginalTargetReported(initialTargetReported);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalTargetReported !== null) {
      const filteredData = originalTargetReported.filter((targetReported) =>
        targetReported.pekerjaan.toLowerCase().includes(searchText.toLowerCase())
      );

      setTargetReported(filteredData);
    }
  }, [searchText, originalTargetReported]);

  const columns = [
    {
      title: "#",
      dataIndex: "pihakTerlaporID", // You can adjust this based on your API response structure
      key: "pihakTerlaporID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Pekerjaan",
      dataIndex: "pekerjaan", // Adjust this based on your API response structure
      key: "pekerjaan",
    },
  ];

  if (canUpdateTerlapor || canRemoveTerlapor) {
    columns.push({
      title: 'Aksi',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {canUpdateTerlapor && (
            <Button type="primary" size="default" onClick={() => showModal(record)}>Perbarui</Button>
          )}

          {canRemoveTerlapor && (
            <Button type="primary" danger size="default" onClick={() => handleDelete(record)}>Hapus</Button>
          )}
        </Space>
      ),
    });
  }

  return (
    <div>
      <Card>
        <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
          {canAddTerlapor && (
            <Col><Button type="primary" onClick={handleAdd}>Tambah Pihak Terlapor</Button> </Col>
          )}
          <Col>
            <div className="search-bar">
              <Input
                placeholder="Mencari..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                prefix={<SearchOutlined />}
              />
            </div>
          </Col>
        </Row>

        {targetReported && targetReported.length > 0 && canViewTerlapor ? (
          <Table
            dataSource={targetReported}
            columns={columns}
            scroll={{ x: true }}
            pagination={{
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
              total: targetReported.length,
              showSizeChanger: false,
              showQuickJumper: false,
              showLessItems: true,
            }}
          />
        ) : (
          <Table
            dataSource={[]}
            columns={columns}
            pagination={false} // Disable pagination when there is no data
          />
        )}
      </Card>

      {/* Modal for Adding Target */}
      {canAddTerlapor && (
        <Modal
          title="Tambah Pihak Terlapor"
          visible={addVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinishAdd} // Use the onFinishAdd function for adding
          >
            <Form.Item
              label="Pekerjaan"
              name="pekerjaan"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan pekerjaan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {canUpdateTerlapor && (
        <Modal
          title="Perbarui Pihak Terlapor"
          visible={updateVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            form={form}
            onFinish={onFinish}
          >
            <Form.Item
              label="Pekerjaan"
              name="pekerjaan"
              rules={[
                {
                  required: true,
                  message: "Silahkan memasukkan pekerjaan!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Target;
