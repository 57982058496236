import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { BASE_URL } from "../../config";
import { getApiConfig } from "../../lib/getApiConfig";

function PieChart2() {
  const [summary, setSummary] = useState([]);
  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('levelDetail')) || [];
  let isComponentMounted = true;
  const configApi = getApiConfig()

  const summaryKategoriLaporan = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-groupby-kategori-laporan", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00" && isComponentMounted) {
        setSummary(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  useEffect(() => {
    summaryKategoriLaporan();
  }, []);

  if (!summary || summary.length === 0) {
    return <p>Data Not Found!</p>;
  }

  const labels = summary.map(item => item.laporanKategoriLaporan);
  const values = summary.map(item => item.totalLaporan);

  const pieChartOptions = {
    chart: {
      type: "pie",
      width: "100%",
      height: "100%",
    },
    labels: labels,
    legend: {
      position: 'bottom'
    }
  };

  return (
    <div>
      {summary.length > 0 ? (
        <ReactApexChart options={pieChartOptions} series={values} type="pie" labels={labels} />
      ) : (
        <p>Data Not Found!</p>
      )}
    </div>
  );
}

export default PieChart2;
