import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Input, Select, message, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { BASE_URL } from "../config";
import { getApiConfig } from "../lib/getApiConfig";
import Swal from "sweetalert2";

const Action = () => {
  const configApi = getApiConfig()

  const getLaporanList = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/list", {
        method: "GET",
        headers: configApi,
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil data laporan :", error);
      return [];
    }
  };

  const getDataDiriList = async () => {
    try {
      const response = await fetch(BASE_URL + "/target/list-data-pelapor", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat mengambil datadiri :", error);
      return [];
    }
  };

  const [currentPage, setCurrentPage] = useState(1);

  const [action, setAction] = useState([]);

  const [addVisible, setAddVisible] = useState(false); // Separate state for adding

  const [updateVisible, setUpdateVisible] = useState(false); // Separate state for updating

  const [currentAction, setCurrentAction] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState("");

  const [selectedDescription, setSelectedDescription] = useState("");

  const [searchText, setSearchText] = useState("");

  const [filteredAction, setFilteredAction] = useState([]);

  const [form] = Form.useForm();

  const { confirm } = Modal;

  const [originalAction, setOriginalAction] = useState([]);

  // Get privileges from session storage
  const storedPrivileges = JSON.parse(sessionStorage.getItem('privileges')) || [];

  // Use the user object and its privileges to conditionally render components
  const canViewAction = storedPrivileges.includes("VIEW_ACTION");
  const canAddAction = storedPrivileges.includes("ADD_ACTION");

  const { Option } = Select;

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setAddVisible(false);
    setUpdateVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/action/edit", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          actionID: currentAction.actionID,
          kodeLaporan: values.kodeLaporan,
          deskripsi: values.deskripsi,
          status: values.status,
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      console.log("Tindak lanjut laporan berhasil diperbahrui");
      setUpdateVisible(false);
      // Refresh the action list after successful update
      getAction().then((data) => {
        setAction(data);
        setOriginalAction(data); // Update originalAction with the new data
        setFilteredAction(data); // Update filteredAction with the new data
      });
    } catch (error) {
      console.error("Gagal Memperbarui tindak lanjut laporan :", error);
    }
  };

  const handleAdd = () => {
    setSelectedStatus(""); // Clear the selected role group
    form.resetFields(); // Clear the form fields
    setAddVisible(true);
  };

  const onFinishAdd = async (values) => {
    Swal.fire({
      title: "Apakah Anda yakin?",
      text: "Pastikan data yang Anda isi sudah benar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ya, lanjutkan!",
      cancelButtonText: "Tidak",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "ant-btn ant-btn-primary",
        cancelButton: "ant-btn me-3 mr-3"
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleFinish()
      }
    });

    const handleFinish = async () => {
      try {
        const laporanList = await getLaporanList();
        const dataDiriList = await getDataDiriList();

        const selectedLaporan = laporanList.find(laporan => laporan.kodeLaporan === values.kodeLaporan);

        if (!selectedLaporan) {
          message.error("Kode Laporan tidak ditemukan");
          return;
        }

        if (selectedLaporan.status === 'Belum Diproses') {
          message.error("Laporan Belum Diproses");
          return;
        }

        if (selectedLaporan.status === 'Ditolak') {
          message.error("Laporan Ditolak");
          return;
        }

        const kodeLaporanExistsInLaporan = laporanList.some((laporan) => laporan.kodeLaporan === values.kodeLaporan);

        if (!kodeLaporanExistsInLaporan) {
          message.error("Kode Laporan tidak ditemukan"); // Show an alert message
          return; // Exit the function to prevent further processing
        }

        const kodeLaporanExists = action.some((action) => action.kodeLaporan === values.kodeLaporan);

        if (kodeLaporanExists) {
          message.error("Laporan Sudah Ditangani");
          return
        } else {
          // Rest of your code for adding the action...
          // (the part where you check the eligibility and add the action)
          const isEligible = laporanList.some(
            (laporan) => {
                return (
                  (laporan.status === "Diterima" && laporan.dataDiri === "N") ||
                  (laporan.status === "Diterima" &&
                    laporan.dataDiri === "Y" &&
                    dataDiriList.some((data) => data.kodeLaporan === laporan.kodeLaporan))
                );
            }
          );

          if (isEligible) {
            // Cek apakah kode laporan yang akan ditambahkan sesuai kriteria
            const isKodeLaporanValid = laporanList.some(
              (laporan) => laporan.kodeLaporan === values.kodeLaporan
            );

            if (isKodeLaporanValid) {
              const response = await fetch(BASE_URL + "/action/add", {
                method: "POST",
                headers: configApi,
                body: JSON.stringify({
                  kodeLaporan: values.kodeLaporan,
                  deskripsi: values.deskripsi,
                  status: selectedStatus,
                }),
              });

              if (!response.ok) {
                throw new Error("Respons jaringan tidak baik");
              }

              // Refresh the action list after successful addition
              // const data = await getAction();
              // setAction(data);
              getAction().then((data) => {
                setAction(data);
                setOriginalAction(data); // Update originalAction with the new data
                setFilteredAction(data); // Update filteredAction with the new data
              });

              console.log("Tindak lanjut laporan berhasil ditambahkan");
              message.success("Tindak lanjut laporan berhasil ditambahkan");
              setAddVisible(false);
            } else {
              console.error("Kode laporan tidak memenuhi kriteria.");
              message.error("Kode laporan tidak memenuhi kriteria.");
            }
          } else {
            // Display the alert when the condition is met
            message.error("Kode laporan tidak memenuhi kriteria.");
          }
        }
      } catch (error) {
        console.error("Gagal menambahkan tindak lanjut laporan :", error);
        message.error("Gagal menambahkan tindak lanjut laporan");
      }
    }
  };

  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('levelDetail')) || [];

  const getAction = async () => {
    try {
      const response = await fetch(BASE_URL + "/action/list", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error("Kesalahan saat mengambil data :", error);
      return [];
    }
  };

  useEffect(() => {
    getAction().then((data) => {
      const initialAction = data || [];
      setAction(initialAction);
      setOriginalAction(initialAction);
    });
  }, []);

  useEffect(() => {
    // Implement the search here, e.g., filter the 'originalCategories' based on 'searchText'
    if (originalAction !== null) { // Check if originalAction is not null
      const filteredData = originalAction.filter((action) =>
        action.kodeLaporan.toLowerCase().includes(searchText.toLowerCase()) ||
        action.deskripsi.toLowerCase().includes(searchText.toLowerCase()) ||
        action.status.toLowerCase().includes(searchText.toLowerCase())
      );
      setAction(filteredData);
    }
  }, [searchText, originalAction]);

  const columns = [
    {
      title: "#",
      dataIndex: "actionID", // You can adjust this based on your API response structure
      key: "actionID",
      render: (text, record, index) => (currentPage - 1) * 10 + index + 1,
    },
    {
      title: "Kode Laporan",
      dataIndex: "kodeLaporan", // Adjust this based on your API response structure
      key: "kodeLaporan",
    },
    {
      title: "Status Tindak Lanjut Laporan",
      dataIndex: "status", // Adjust this based on your API response structure
      key: "status",
    },
    {
      title: "Tindak Lanjut Laporan",
      dataIndex: "deskripsi", // Adjust this based on your API response structure
      key: "deskripsi",
    },
  ];

  return (
    <div>
      <Row justify="space-between" align="middle" style={{ marginBottom: '16px' }}>
        {canAddAction && (
          <Col>
            <Button type="primary" onClick={handleAdd}>Tambah Tindak Lanjut Laporan</Button>
          </Col>
        )}
        <Col>
          <div className="search-bar">
            <Input
              placeholder="Mencari..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />} // Menambahkan ikon search sebagai prefix
            />
          </div>
        </Col>
      </Row>

      {action && action.length > 0 && canViewAction ? (
        <Table
          dataSource={action}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
            total: action.length,
            showSizeChanger: false,
            showQuickJumper: false,
            showLessItems: true,
          }}
        />
      ) : (
        <Table
          dataSource={[]}
          columns={columns}
          pagination={false} // Disable pagination when there is no data
        />
      )}

      {/* Modal for Adding Action */}
      <Modal
        title="Tambah Tindak Lanjut Laporan"
        visible={addVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={onFinishAdd} // Use the onFinishAdd function for adding
        >
          <Form.Item
            label="Kode Laporan"
            name="kodeLaporan"
            rules={[
              {
                required: true,
                message: "Silahkan memasukkan kode laporan!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status" // Change to the appropriate field name for the selected status
            rules={[
              {
                required: true,
                message: "Silahkan memilih status tindak lanjut laporan!",
              },
            ]}
          >
            <Select
              placeholder="Silahkan memilih tindak lanjut laporan!"
              onChange={(value) => setSelectedStatus(value)} // Update the state with selected status
            >
              <Option value="Terbukti dan signifikan">Terbukti dan signifikan</Option>
              <Option value="Terbukti tapi dampak tidak signifikan">Terbukti tapi dampak tidak signifikan</Option>
              <Option value="Tidak terbukti">Tidak terbukti</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Tindak Lanjut Laporan"
            name="deskripsi"
            rules={[
              {
                required: selectedStatus !== 'Tidak terbukti',
                message: "Silahkan memilih tindak lanjut!",
              },
            ]}
          >
            <Select
              placeholder="Silahkan memilih tindak lanjut laporan!"
              disabled={selectedStatus === 'Tidak terbukti'}
              onChange={(value) => setSelectedDescription(value)} // Update the state with selected status
            >
              <Option value="Penerapan Sanksi">Penerapan Sanksi</Option>
              <Option value="Perubahan Kebijakan">Perubahan Kebijakan</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Memperbarui Tindak Lanjut Laporan"
        visible={updateVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Kode Laporan"
            name="kodeLaporan"
            rules={[
              {
                required: true,
                message: "Silahkan memasukkan kode laporan!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status" // Change to the appropriate field name for the selected status
            rules={[
              {
                required: true,
                message: "Silahkan memilih status tindak lanjut laporan!",
              },
            ]}
          >
            <Select
              placeholder="Silahkan memilih status tindak lanjut laporan!"
              onChange={(value) => setSelectedStatus(value)} // Update the state with selected status
            >
              <Option value="Terbukti dan signifikan">Terbukti dan signifikan</Option>
              <Option value="Terbukti tapi dampak tidak signifikan">Terbukti tapi dampak tidak signifikan</Option>
              <Option value="Tidak terbukti">Tidak terbukti</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Tindak Lanjut Laporan"
            name="deskripsi"
            rules={[
              {
                required: true,
                message: "Silahkan memilih tindak lanjut laporan!",
              },
            ]}
          >
            <Select
              placeholder="Silahkan memilih tindak lanjut laporan!"
              onChange={(value) => setSelectedDescription(value)} // Update the state with selected status
            >
              <Option value="Penerapan Sanksi">Penerapan Sanksi</Option>
              <Option value="Perubahan Kebijakan">Perubahan Kebijakan</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Action;