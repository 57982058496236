import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
  
    const stopPropagation = (e) => {
      e.stopPropagation();
    };
  
    return (
      <div className="modal" onClick={onClose}>
          {children}
      </div>
    );
};

export default Modal;
