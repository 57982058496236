import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

export const RouteGuard = ({ component: Component, ...rest }) => {
    const history = useHistory();

    function hasJWT() {
        let flag = false;

        //check user has JWT token
        sessionStorage.getItem("accessToken") ? flag = true : flag = false

        return flag
    }

    function clearSession() {
        sessionStorage.removeItem("expire");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("privileges");
        sessionStorage.removeItem("levelDetail");
        sessionStorage.removeItem("allReport");
        sessionStorage.removeItem("isGoogle");

        toast.error("Sesi sudah habis, silahkan sign in ulang!", {
            position: toast.POSITION.TOP_CENTER,
        });

        history.push("/sign-in");
    }

    function checkAndRemoveExpired() {
        var storedExpire = sessionStorage.getItem("expire");

        if (storedExpire) {
            var expirationTime = new Date(storedExpire);

            var currentTime = new Date();

            if (currentTime > expirationTime) {
                clearSession()
            }
        }
    }

    useEffect(() => {
        checkAndRemoveExpired();

        const intervalId = setInterval(() => {
            checkAndRemoveExpired();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);


    return (
        <Route {...rest}
            render={props => (
                hasJWT() ?
                    <Component {...props} />
                    :
                    <Redirect to={{ pathname: '/sign-in' }} />
            )}
        />
    );
};

export const AlterRouteGuard = ({ component: Component, ...rest }) => {
    function hasJWT() {
        let flag = false;

        //check user has JWT token
        sessionStorage.getItem("accessToken") ? flag = true : flag = false
        console.log(sessionStorage.getItem("accessToken"))
        return flag
    }

    return (
        <Route {...rest}
            render={props => (
                !hasJWT() ?
                    <Component {...props} />
                    :
                    <Redirect to={{ pathname: '/dashboard' }} />
            )}
        />
    );
};