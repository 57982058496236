import { AiOutlinePaperClip } from "react-icons/ai";
import { BASE_URL } from "../config";
import { Button } from "@mui/material";

export const renderAttachment = (lampirans, isInline = false) => {
    let lampiran;

    try {
        lampiran = JSON.parse(lampirans);
    } catch (error) {
        lampiran = [];
    }

    const filteredLampiran = lampiran?.filter((item) => item !== "");

    if (filteredLampiran?.length) {
        const attachments = filteredLampiran.map((lampiran, index) => {
            if (isInline) {
                return <a
                    key={index}
                    href={`${BASE_URL}/${lampiran}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", alignItems: "center", gap: "2px" }}
                    className="btn bg-tw-secondary-light text-white hover:bg-tw-secondary-light h-fit"
                >
                    <AiOutlinePaperClip />
                    Lampiran {index + 1}
                </a>
            } else {
                if (
                    lampiran.endsWith(".jpg") ||
                    lampiran.endsWith(".jpeg") ||
                    lampiran.endsWith(".webp") ||
                    lampiran.endsWith(".svg") ||
                    lampiran.endsWith(".png")
                ) {
                    const imagePath = `${BASE_URL}/${lampiran}`;
                    return (
                        <a
                            key={index}
                            href={`${BASE_URL}/${lampiran}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textAlign: "center", color: "gray" }}
                        >
                            <label htmlFor="">Lampiran {index + 1}</label>
                            <img
                                src={imagePath}
                                alt="Attachment"
                                style={{ width: "100%" }}
                                className="border-[1px] border-slate-200"
                            />
                        </a>
                    );
                } else {
                    return (
                        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="">Lampiran {index + 1}</label>
                            <Button type="primary" style={{ background: '#1890ff', color: 'white' }}>
                                <a
                                    key={index}
                                    href={`${BASE_URL}/${lampiran}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ display: "flex", alignItems: "center", gap: "8px", color: 'white' }}
                                    className="btn bg-tw-secondary-light text-white hover:bg-tw-secondary-light h-fit"
                                >
                                    <AiOutlinePaperClip />
                                    <div style={{
                                        "overflow": "hidden", textOverflow: "ellipsis", "whiteSpace": "nowrap", maxWidth: "200px"
                                    }}>
                                        {lampiran.replace("storage/", "")}
                                    </div>
                                </a>
                            </Button>
                        </div >
                    );
                }
            }
        });

        return attachments;
    } else {
        return "Tidak ada lampiran"
    }
};
