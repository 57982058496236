import { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { BASE_URL } from "../config";
import { googleLogout } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import { Tabs } from "antd";
import ReactApexChart from "react-apexcharts";
import Modal from './Modal';
import '../assets/styles/home.css';
import { IoFilter } from "react-icons/io5";
import { LuCalendarDays, LuCalendarCheck, LuFolderSync, LuBadgeCheck, LuBadgeX, LuCalendarX2, LuCalendarClock, LuFileSignature } from "react-icons/lu";
import { Modal as Modal2 } from "antd";

import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Form
} from "antd";

import PieChart from "../components/chart/PieChart";
import PieChart2 from "../components/chart/PieChart2";
import { renderAttachment } from "../components/renderAttachment";
import { getApiConfig } from "../lib/getApiConfig";

const { RangePicker } = DatePicker;

function Home() {
  const configApi = getApiConfig()
  const { Title } = Typography;
  const history = useHistory();
  const [reverse, setReverse] = useState({});
  const [selectedReportThisMonth, setSelectedReportThisMonth] = useState({ totalLaporan: 0 });
  const [reportThisMonth, setReportThisMonth] = useState([]);
  const [reportBelumDitindaklanjuti, setReportBelumDitindaklanjuti] = useState([]);
  const [reportDiterima, setReportDiterima] = useState([]);
  const [reportSelesai, setReportSelesai] = useState([]);
  const [reportDitolak, setReportDitolak] = useState([]);
  const [reportDiterimaBelumIsiDataDiri, setReportDiterimaBelumIsiDataDiri] = useState([]);
  const [reportHariIni, setReportHariIni] = useState([]);
  const [reportKedaluwarsa, setReportKedaluwarsa] = useState([]);

  const [selectedReportToday, setSelectedReportToday] = useState({ totalLaporan: 0 });

  const [selectedReportNotFollowUp, setSelectedReportNotFollowUp] = useState({ totalLaporan: 0 });

  const [selectedReportReceived, setSelectedReportReceived] = useState({ totalLaporan: 0 });

  const [selectedReportSelesai, setSelectedReportSelesai] = useState({ totalLaporan: 0 });

  const [selectedReportRejected, setSelectedReportRejected] = useState({ totalLaporan: 0 });

  const [selectedReportReceivedButNotFilledPersonalData, setSelectedReportReceivedButNotFilledPersonalData] = useState({ totalLaporan: 0 });
  const [selectedReportKedaluwarsa, setSelectedReportKedaluwarsa] = useState({ totalLaporan: 0 });

  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [currentReport, setCurrentReport] = useState(null);
  const [isDescriptionShow, setIsDescriptionShow] = useState(false)
  const [isLampiranShow, setIsLampiranShow] = useState(false)

  const showDescModal = (report) => {
    setCurrentReport(report);
    setIsDescriptionShow(true);
  };

  const showLampiranModal = (report) => {
    setCurrentReport(report);
    setIsLampiranShow(true);
  };

  const todayDate = moment();
  // const defaultDate = moment().subtract(30, 'days');
  const defaultDate = moment().subtract(30, 'days');
  const today = new Date();
  const today2 = new Date();
  today2.setFullYear(today.getFullYear() - 2);
  const formattedMonth = today.toLocaleDateString('id-ID', { month: 'long', year: 'numeric' });
  const formattedYear = today.toLocaleDateString('id-ID', { year: 'numeric' });
  const formatted3Year = today2.toLocaleDateString('id-ID', { year: 'numeric' });

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const currentYear = today.getFullYear(); // Get the current year

  const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st of the current year
  const lastDayOfYear = new Date(currentYear, 11, 31);

  const sessionLevelDetail = JSON.parse(sessionStorage.getItem('levelDetail')) || [];

  // ECHART 1
  const [dailyDataEChart1, setDailyDataEChart1] = useState([]);
  const [weeklyDataEChart1, setWeeklyDataEChart1] = useState([]);
  const [monthlyDataEChart1, setMonthlyDataEChart1] = useState([]);
  const [yearlyDataEChart1, setYearlyDataEChart1] = useState([]);

  const { TabPane } = Tabs;

  const [dates, setDates] = useState(null);
  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 30;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const dailyReportEChart1 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart1(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Gagal Menghitung Laporan Harian :", error);
    }
  };

  const weeklyReportEChart1 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart1(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart1 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart1(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart1 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart1(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart1 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart1.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart1 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart1.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart1 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart1.map((item) => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart1 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart1.map((item) => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 2
  const [dailyDataEChart2, setDailyDataEChart2] = useState([]);
  const [weeklyDataEChart2, setWeeklyDataEChart2] = useState([]);
  const [monthlyDataEChart2, setMonthlyDataEChart2] = useState([]);
  const [yearlyDataEChart2, setYearlyDataEChart2] = useState([]);

  const dailyReportEChart2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Diterima',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart2(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Diterima',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart2(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Diterima',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart2(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart2 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Diterima',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart2(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart2 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart2.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart2 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart2.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart2 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart2.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart2 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart2.map(item => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Count", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 3
  const [dailyDataEChart3, setDailyDataEChart3] = useState([]);
  const [weeklyDataEChart3, setWeeklyDataEChart3] = useState([]);
  const [monthlyDataEChart3, setMonthlyDataEChart3] = useState([]);
  const [yearlyDataEChart3, setYearlyDataEChart3] = useState([]);

  const dailyReportEChart3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Selesai',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart3(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Selesai',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart3(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Selesai',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart3(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart3 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Selesai',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart3(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart3 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart3.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart3 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart3.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart3 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart3.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart3 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart3.map(item => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 4
  const [dailyDataEChart4, setDailyDataEChart4] = useState([]);
  const [weeklyDataEChart4, setWeeklyDataEChart4] = useState([]);
  const [monthlyDataEChart4, setMonthlyDataEChart4] = useState([]);
  const [yearlyDataEChart4, setYearlyDataEChart4] = useState([]);

  const dailyReportEChart4 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Belum Diproses',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart4(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart4 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Belum Diproses',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart4(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart4 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Belum Diproses',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart4(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart4 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Belum Diproses',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart4(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart4 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart4.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart4 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart4.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart4 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart4.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart4 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart4.map(item => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 5
  const [dailyDataEChart5, setDailyDataEChart5] = useState([]);
  const [weeklyDataEChart5, setWeeklyDataEChart5] = useState([]);
  const [monthlyDataEChart5, setMonthlyDataEChart5] = useState([]);
  const [yearlyDataEChart5, setYearlyDataEChart5] = useState([]);

  const dailyReportEChart5 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Ditolak',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart5(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart5 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Ditolak',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart5(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart5 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Ditolak',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart5(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart5 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: 'Ditolak',
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart5(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart5 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart5.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart5 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart5.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart5 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart5.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart5 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart5.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 6
  const [dailyDataEChart6, setDailyDataEChart6] = useState([]);
  const [weeklyDataEChart6, setWeeklyDataEChart6] = useState([]);
  const [monthlyDataEChart6, setMonthlyDataEChart6] = useState([]);
  const [yearlyDataEChart6, setYearlyDataEChart6] = useState([]);

  const dailyReportEChart6 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart6(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart6 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart6(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart6 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart6(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart6 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart6(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart6 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart6.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart6 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart6.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart6 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart6.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart6 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart6.map(item => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  // ECHART 7
  const [dailyDataEChart7, setDailyDataEChart7] = useState([]);
  const [weeklyDataEChart7, setWeeklyDataEChart7] = useState([]);
  const [monthlyDataEChart7, setMonthlyDataEChart7] = useState([]);
  const [yearlyDataEChart7, setYearlyDataEChart7] = useState([]);

  const dailyReportEChart7 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-daily2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setDailyDataEChart7(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report daily:", error);
    }
  };

  const weeklyReportEChart7 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-weekly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setWeeklyDataEChart7(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report weekly:", error);
    }
  };

  const monthlyReportEChart7 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-monthly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setMonthlyDataEChart7(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report monthly:", error);
    }
  };

  const yearlyReportEChart7 = async () => {
    try {
      const response = await fetch(BASE_URL + "/report/summary-yearly2", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setYearlyDataEChart7(data.result);
      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Error count report yearly:", error);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [selectedDateTo, selectedDateFrom]);

  const dailyChartOptionsEChart7 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: dailyDataEChart7.map(item => item.date), // X-axis labels
      title: {
        text: "Date", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const weeklyChartOptionsEChart7 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: weeklyDataEChart7.map(item => item.date), // X-axis labels
      title: {
        text: "Week", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const monthlyChartOptionsEChart7 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: monthlyDataEChart7.map(item => item.date), // X-axis labels
      title: {
        text: "Month", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const yearlyChartOptionsEChart7 = {
    chart: {
      type: "bar",
      width: "100%",
      height: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: yearlyDataEChart7.map(item => item.date), // X-axis labels
      title: {
        text: "Year", // X-axis label
      },
      labels: {
        show: true,
        align: "right",
        style: {
          colors: "#000", // Color of the X-axis labels
        },
      },
    },
    yaxis: {
      title: {
        text: "Total", // Y-axis label
      },
      forceNiceScale: true,
      labels: {
        formatter: (value) => Math.round(value), // Ini akan membulatkan nilai ke angka bulat
      },
    },
  };

  const totalLaporanHariIni = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: today.toISOString(),
          dateTo: today.toISOString(),
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportToday(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanHariIni = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: today.toISOString(),
          dateTo: today.toISOString(),
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  useEffect(() => {
    totalLaporanHariIni();

    dailyReportEChart1();
    weeklyReportEChart1();
    monthlyReportEChart1();
    yearlyReportEChart1();

    dailyReportEChart2();
    weeklyReportEChart2();
    monthlyReportEChart2();
    yearlyReportEChart2();

    dailyReportEChart3();
    weeklyReportEChart3();
    monthlyReportEChart3();
    yearlyReportEChart3();

    dailyReportEChart4();
    weeklyReportEChart4();
    monthlyReportEChart4();
    yearlyReportEChart4();

    dailyReportEChart5();
    weeklyReportEChart5();
    monthlyReportEChart5();
    yearlyReportEChart5();

    dailyReportEChart6();
    weeklyReportEChart6();
    monthlyReportEChart6();
    yearlyReportEChart6();

    dailyReportEChart7();
    weeklyReportEChart7();
    monthlyReportEChart7();
    yearlyReportEChart7();

    detailLaporanBulanIni().then((data) => {
      setReportThisMonth(data);
    });

    detailLaporanBelumDitindaklanjuti().then((data) => {
      setReportBelumDitindaklanjuti(data);
    });

    detailLaporanDiterima().then((data) => {
      setReportDiterima(data);
    });

    detailLaporanSelesai().then((data) => {
      setReportSelesai(data);
    });

    detailLaporanDitolak().then((data) => {
      setReportDitolak(data);
    });

    detailLaporanDiterimaBelumIsiDataDiri().then((data) => {
      setReportDiterimaBelumIsiDataDiri(data);
    });

    detailLaporanHariIni().then((data) => {
      setReportHariIni(data);
    });

    detailLaporanKedaluwarsa().then((data) => {
      setReportKedaluwarsa(data);
    });
  }, []);

  const totalLaporanBulanIni = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: firstDayOfMonth,
          dateTo: lastDayOfMonth,
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportThisMonth(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanBulanIni = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: firstDayOfMonth,
          dateTo: lastDayOfMonth,
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanBelumDitindaklanjuti = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: selectedDateFrom,
          dateTo: selectedDateTo,
          laporanStatus: "Belum Diproses",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportNotFollowUp(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanBelumDitindaklanjuti = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: selectedDateFrom,
          dateTo: selectedDateTo,
          laporanStatus: "Belum Diproses",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanDiterima = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportReceived(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanDiterima = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanAll = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom: selectedDateFrom,
          dateTo: selectedDateTo,
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanSelesai = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Selesai",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportSelesai(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanSelesai = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Selesai",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanDitolak = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Ditolak",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportRejected(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanDitolak = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Ditolak",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanDiterimaBelumIsiDataDiri = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportReceivedButNotFilledPersonalData(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanDiterimaBelumIsiDataDiri = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          dataDiri: "Y",
          kodeLaporanExistsInDataPelapor: "True",
          laporanStatus: "Diterima",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const totalLaporanKedaluwarsa = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/summary", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      if (data.responseCode === "00") {
        setSelectedReportKedaluwarsa(data.result);

      } else {
        console.error("Respons API menunjukkan kesalahan :", data.responseMessage);
      }
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  const detailLaporanKedaluwarsa = async (values) => {
    try {
      const response = await fetch(BASE_URL + "/report/list-advance", {
        method: "POST",
        headers: configApi,
        body: JSON.stringify({
          dateFrom2: selectedDateFrom,
          dateTo2: selectedDateTo,
          laporanStatus: "Kedaluwarsa",
          tingkatanDetail: sessionLevelDetail
        }),
      });
      if (!response.ok) {
        throw new Error("Respons jaringan tidak baik");
      }
      const data = await response.json(); // Parse the response as JSON

      return data.result;
    } catch (error) {
      console.error("Terjadi kesalahan saat memperbarui laporan :", error);
    }
  };

  useEffect(() => {
    // Inisialisasi tanggal 1 bulan sebelum hari ini
    const oneMonthAgo = moment().subtract(1, 'month');
    // atau
    // const oneMonthAgo = format(addMonths(new Date(), -1), 'yyyy-MM-dd'); // Jika menggunakan date-fns

    setSelectedDateFrom(oneMonthAgo);
    setSelectedDateTo(new Date()); // Atau sesuaikan dengan tanggal yang Anda inginkan
    handleFilter();
  }, []);

  const handleFilter = async () => {
    try {
      totalLaporanBulanIni();
      totalLaporanBelumDitindaklanjuti();
      totalLaporanDiterima();
      totalLaporanSelesai();
      totalLaporanDitolak();
      totalLaporanDiterimaBelumIsiDataDiri();
      totalLaporanKedaluwarsa();
    } catch (error) {
      console.error('Kesalahan dalam handleFilter :', error);
    }
  };

  const transactionpermonth = [
    <svg
      width="22"
      height="22"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15,5L15,5c0-0.552-0.448-1-1-1h-1.051C12.982,4.162,13,4.329,13,4.5v2C13,7.878,11.879,9,10.5,9
        c-0.107,0-0.215-0.007-0.325-0.021C8.935,8.82,8,7.732,8,6.448V6.303c0-0.764,0.549-1.446,1.307-1.541
        C9.552,4.731,9.787,4.76,10,4.836V4.5C10,4.224,10.224,4,10.5,4S11,4.224,11,4.5v1.972c0,0.25-0.171,0.482-0.418,0.522
        C10.554,6.998,10.527,7,10.5,7C10.224,7,10,6.775,10,6.5V6.25c0-0.304-0.271-0.544-0.584-0.493C9.17,5.797,9,6.028,9,6.277v0.162
        c0,0.778,0.566,1.472,1.34,1.553C10.394,7.997,10.447,8,10.5,8C11.327,8,12,7.327,12,6.5v-2C12,3.672,11.328,3,10.5,3
        C9.848,3,9.298,3.419,9.092,4H8.051H6C5.448,4,5,4.448,5,5v10c0,0.552,0.448,1,1,1h8c0.552,0,1-0.448,1-1L15,5L15,5z M11.5,13h-3
        C8.224,13,8,12.776,8,12.5C8,12.224,8.224,12,8.5,12h3c0.276,0,0.5,0.224,0.5,0.5C12,12.776,11.776,13,11.5,13z M11.5,11h-3
        C8.224,11,8,10.776,8,10.5C8,10.224,8.224,10,8.5,10h3c0.276,0,0.5,0.224,0.5,0.5C12,10.776,11.776,11,11.5,11z M30,25.618V26
        c0,1.657-1.343,3-3,3H5c-1.657,0-3-1.343-3-3v-0.382C2.734,26.458,3.8,27,5,27h22C28.2,27,29.266,26.458,30,25.618z M5,26h22
        c1.657,0,3-1.343,3-3V8c0-1.657-1.343-3-3-3H16v10c0,1.103-0.897,2-2,2H6c-1.103,0-2-0.897-2-2V5.184C2.837,5.597,2,6.696,2,8v15
        C2,24.657,3.343,26,5,26z M5.5,22h2.793l2.854-2.854C11.24,19.053,11.367,19,11.5,19h3.793l2.854-2.854
        c0.195-0.195,0.512-0.195,0.707,0L20.707,18h1.586l3-3H22.5c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h4
        c0.065,0,0.13,0.013,0.191,0.038c0.122,0.051,0.22,0.148,0.271,0.271C26.987,14.37,27,14.435,27,14.5v4c0,0.276-0.224,0.5-0.5,0.5
        S26,18.776,26,18.5v-2.793l-3.146,3.146C22.76,18.947,22.633,19,22.5,19h-2c-0.133,0-0.26-0.053-0.354-0.146L18.5,17.207
        l-2.646,2.646C15.76,19.947,15.633,20,15.5,20h-3.793l-2.854,2.854C8.76,22.947,8.633,23,8.5,23h-3C5.224,23,5,22.776,5,22.5
        S5.224,22,5.5,22z"
        fill="#fff"
      ></path>
    </svg>,
  ];
  const ReportThisMonth = selectedReportThisMonth.totalLaporan;
  const ReportToday = selectedReportToday.totalLaporan;
  const ReportNotFollowUp = selectedReportNotFollowUp.totalLaporan;
  const ReportReceived = selectedReportReceived.totalLaporan;
  const ReportSelesai = selectedReportSelesai.totalLaporan;
  const ReportRejected = selectedReportRejected.totalLaporan;
  const ReportReceivedButNotFilledPersonalData = selectedReportReceivedButNotFilledPersonalData.totalLaporan;
  const ReportKedaluwarsa = selectedReportKedaluwarsa.totalLaporan;

  // State to manage which modal is open
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [reportType, setReportType] = useState("");

  // ... existing useEffect and function implementations

  // Open modal handler
  const openModal = (reportType) => {
    console.log('openModal called with reportType:', reportType);
    setIsModalOpen(true); // We use this state to control the modal visibility
    setReportType(reportType);
    switch (reportType) {
      case 'Total Laporan Bulan Ini':
        detailLaporanBulanIni().then(setModalContent);
        break;
      case 'Total Laporan Diterima':
        detailLaporanDiterima().then(setModalContent);
        break;
      case 'Total Laporan Selesai':
        detailLaporanSelesai().then(setModalContent);
        break;
      case 'Total Laporan Ditolak':
        detailLaporanDitolak().then(setModalContent);
        break;
      case 'Total Laporan Belum Diproses':
        detailLaporanBelumDitindaklanjuti().then(setModalContent);
        break;
      case 'Total Laporan Diterima Belum Isi Data Diri':
        detailLaporanDiterimaBelumIsiDataDiri().then(setModalContent);
        break;
      case 'Total Laporan Masuk Hari ini':
        detailLaporanHariIni().then(setModalContent);
        break;
      case 'Total Laporan Kedaluwarsa':
        detailLaporanKedaluwarsa().then(setModalContent);
        break;
      default:
        setModalContent(null);
    }
  };

  // Close modal handler
  const closeModal = () => {
    setIsModalOpen(false); // This will close the modal
    setModalContent(null);
  };

  const count = [
    {
      today: "Total Laporan Bulan " + formattedMonth,
      title: ReportThisMonth,
      icon: <LuCalendarDays style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Bulan Ini'),
    },
    {
      today: "Total Laporan Masuk Hari Ini",
      title: ReportToday,
      icon: <LuCalendarCheck style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Masuk Hari ini'),
    },
    {
      today: "Total Laporan Diterima",
      title: ReportReceived,
      icon: <LuFolderSync style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Diterima'),
    },
    {
      today: "Total Laporan Selesai Ditangani",
      title: ReportSelesai,
      icon: <LuBadgeCheck style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Selesai'),
    },
    {
      today: "Total Laporan Ditolak",
      title: ReportRejected,
      icon: <LuBadgeX style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Ditolak'),
    },
    {
      today: "Total Laporan Kedaluwarsa",
      title: ReportKedaluwarsa,
      icon: <LuCalendarX2 style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Kedaluwarsa'),
    },
    {
      today: "Total Laporan Belum Diproses",
      title: ReportNotFollowUp,
      icon: <LuCalendarClock style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Belum Diproses'),
    },
    {
      today: "Total Laporan Belum Mengisi Data Diri",
      title: ReportReceivedButNotFilledPersonalData,
      icon: <LuFileSignature style={{ fontSize: "18px" }} />,
      bnb: "bnb2",
      onClick: () => openModal('Total Laporan Diterima Belum Isi Data Diri'),
    },
  ];

  const handleLogout = () => {
    googleLogout();
    // Remove the access token from session storage
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("privileges");
    sessionStorage.removeItem("levelDetail");
    sessionStorage.removeItem("allReport");
    // Remove the access token cookie
    Cookies.remove("accessToken");
    history.push('/sign-in');
    // Redirect the user to the login page or perform other required actions.
    // Example: window.location.href = '/login';
  };

  useEffect(() => {
    totalLaporanBulanIni();
  }, [])


  return (
    <>
      <div className="row g-3">
        <Card className="mb-5">
          <div className="filter-container">
            {/* Kolom Filter 1 */}
            <Space direction="vertical" size={2}>
              <label htmlFor="">Filter berdasarkan tanggal</label>
              <Space direction="horizontal" size={8}>
                <RangePicker style={{ padding: "10px 24px" }} defaultValue={[defaultDate, todayDate]} onChange={(value) => {
                  if (value) {
                    setSelectedDateFrom(value[0]); setSelectedDateTo(value[1])
                  } else {
                    setSelectedDateFrom(""); setSelectedDateTo("")
                  }
                }}
                  changeOnBlur
                  onOpenChange={onOpenChange}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => {
                    setDates(val);
                  }}
                />
                <Button type="primary" onClick={handleFilter}>
                  <IoFilter />
                  Filter
                </Button>
              </Space>
            </Space>
          </div>
        </Card>
      </div>

      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col key={index} xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
              <Card
                bordered={false}
                className="circlebox custom-card"
                onClick={c.onClick} // This makes the card clickable
                style={{ cursor: 'pointer', height: "100%", background: "linear-gradient(90deg, rgba(24,144,255,1) 0%, rgba(53,125,191,1) 100%)", color: "white" }} // Adds a pointer cursor on hover
              >
                <div className="number">
                  <Row align="middle" gutter={[24, 0]} style={{ width: "100%" }}>
                    <Col className="flex-card-custom" style={{ width: "100%" }}>
                      <div style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "12px" }}>
                        <span className="text-white" style={{ fontSize: "12px", opacity: '.8' }}>{c.today}</span>
                        <div className="icon-box" style={{ flexShrink: 0, borderRadius: "100px", background: "rgba(255,255,255,.7)", color: "#1853ee" }}>{c.icon}</div>
                      </div>
                      <Title level={3} className="text-white">
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        {isModalOpen && (
          <Modal isOpen={isModalOpen} style={{ width: '100vw', height: '100vh', top: 0, padding: 0, margin: 0 }}>
            <div className="modal-content">
              <div className="modal-header">
                <button onClick={closeModal} className="close-button">&times;</button>
              </div>
              <h2 className="modal-title">{reportType}</h2>
              {Array.isArray(modalContent) ? (
                <div>
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Kode Laporan</th>
                          <th>Nama Kategori</th>
                          <th>Nama Subkategori</th>
                          <th>Pelapor</th>
                          <th>Terlapor</th>
                          <th>Ruang Lingkup</th>
                          <th>Detail Ruang Lingkup</th>
                          <th>Perihal</th>
                          <th>Deskripsi</th>
                          <th>Lampiran</th>
                          <th>Tanggal Kejadian</th>
                          <th>Tanggal Laporan</th>
                          <th>Status Laporan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {modalContent.map((report, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{report.kodeLaporan}</td>
                            <td>{report.namaKategori}</td>
                            <td>{report.namaSubkategori}</td>
                            <td>{report.pekerjaanPelapor}</td>
                            <td>{report.pekerjaanTerlapor}</td>
                            <td>{report.tingkatan}</td>
                            <td>{report.tingkatanDetail}</td>
                            <td>{report.perihal}</td>
                            <td>
                              <a href="#" onClick={() => {
                                closeModal()
                                showDescModal(report)
                              }}>
                                Lihat Deskripsi
                              </a>
                            </td>
                            <td>
                              <a href="#" onClick={() => {
                                closeModal()
                                showLampiranModal(report)
                              }}>
                                Lihat Lampiran
                              </a>
                            </td>
                            <td>
                              {report.tanggalKejadian ?
                                new Date(report.tanggalKejadian).toLocaleDateString("id-ID", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })
                                : 'N/A'
                              }
                            </td>
                            <td>
                              {report.createdAt ?
                                new Date(report.createdAt).toLocaleDateString("id-ID", {
                                  day: "numeric",
                                  month: "long",
                                  year: "numeric",
                                })
                                : 'N/A'
                              }
                            </td>
                            <td>{report.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <p>Tidak ada data tersedia!</p>
              )}
            </div>
          </Modal>
        )}

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart1} series={[{ name: "Total Laporan", data: dailyDataEChart1.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart1} series={[{ name: "Total Laporan", data: weeklyDataEChart1.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart1} series={[{ name: "Total Laporan", data: monthlyDataEChart1.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart1} series={[{ name: "Total Laporan", data: yearlyDataEChart1.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Diterima</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Diterima di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart2} series={[{ name: "Total Laporan", data: dailyDataEChart2.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Diterima di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart2} series={[{ name: "Total Laporan", data: weeklyDataEChart2.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Diterima di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart2} series={[{ name: "Total Laporan", data: monthlyDataEChart2.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Diterima di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart2} series={[{ name: "Total Laporan", data: yearlyDataEChart2.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Selesai</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Selesai di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart3} series={[{ name: "Total Laporan", data: dailyDataEChart3.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Selesai di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart3} series={[{ name: "Total Laporan", data: weeklyDataEChart3.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Selesai di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart3} series={[{ name: "Total Laporan", data: monthlyDataEChart3.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Selesai di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart3} series={[{ name: "Total Laporan", data: yearlyDataEChart3.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Belum Diproses</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Diproses di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart4} series={[{ name: "Total Laporan", data: dailyDataEChart4.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Diproses di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart4} series={[{ name: "Total Laporan", data: weeklyDataEChart4.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Diproses di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart4} series={[{ name: "Total Laporan", data: monthlyDataEChart4.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Diproses di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart4} series={[{ name: "Total Laporan", data: yearlyDataEChart4.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Ditolak</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Ditolak di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart5} series={[{ name: "Total Laporan", data: dailyDataEChart5.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Ditolak di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart5} series={[{ name: "Total Laporan", data: weeklyDataEChart5.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Ditolak di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart5} series={[{ name: "Total Laporan", data: monthlyDataEChart5.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Ditolak di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart5} series={[{ name: "Total Laporan", data: yearlyDataEChart5.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Belum Mengisi Data Diri</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Harian" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Mengisi Data Diri di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart6} series={[{ name: "Total Laporan", data: dailyDataEChart6.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Mingguan" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Mengisi Data Diri di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart6} series={[{ name: "Total Laporan", data: weeklyDataEChart6.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Bulanan" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Mengisi Data Diri di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart6} series={[{ name: "Total Laporan", data: monthlyDataEChart6.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Tahunan" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Belum Mengisi Data Diri di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart6} series={[{ name: "Total Laporan", data: yearlyDataEChart6.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Laporan Kedaluwarsa</h3>
              </div>
              <Tabs defaultActiveKey="1">
                <TabPane tab="Daily" key="1">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Kedaluwarsa di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={dailyChartOptionsEChart7} series={[{ name: "Total Laporan", data: dailyDataEChart7.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Weekly" key="2">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Kedaluwarsa di {formattedMonth}</h5>
                  </div>
                  <ReactApexChart options={weeklyChartOptionsEChart7} series={[{ name: "Total Laporan", data: weeklyDataEChart7.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Monthly" key="3">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Kedaluwarsa di {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={monthlyChartOptionsEChart7} series={[{ name: "Total Laporan", data: monthlyDataEChart7.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
                <TabPane tab="Yearly" key="4">
                  <div style={{ textAlign: 'left' }}>
                    <h5 style={{ marginBottom: '1rem' }}>Total Laporan Kedaluwarsa di {formatted3Year} - {formattedYear}</h5>
                  </div>
                  <ReactApexChart options={yearlyChartOptionsEChart7} series={[{ name: "Total Laporan", data: yearlyDataEChart7.map(item => item.totalLaporan) }]} type="bar" />
                </TabPane>
              </Tabs>
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Per Status</h3>
              </div>
              <PieChart />
            </Card>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginBottom: '1rem' }}>Total Per Kategori</h3>
              </div>
              <PieChart2 />
            </Card>
          </Col>
        </Row>
      </div>

      <Modal2
        title="Deskripsi laporan"
        visible={isDescriptionShow}
        onOk={() => setIsDescriptionShow(false)}
        onCancel={() => setIsDescriptionShow(false)}
      >
        {currentReport?.deskripsi}
      </Modal2>

      <Modal2
        title="Lampiran laporan"
        visible={isLampiranShow}
        onOk={() => setIsLampiranShow(false)}
        onCancel={() => setIsLampiranShow(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {renderAttachment(currentReport?.lampiran)}
        </div>
      </Modal2>
    </>
  );
}

export default Home;
